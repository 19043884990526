.App {
  text-align: center;
}

.html {
  height: 100%;
}

.nav_icon {
  color: #F5F5F5;
  font-size: 28px !important;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100%;
  vertical-align: middle;
}
.nav_button {
  background-color: #02735E;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 0px;
  /* padding-left: 12px;
  padding-right: 30px; */
  padding-top: 5px;
  font-family: "Segoe UI Light";
  text-align: left;
  min-height: 64px;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap:break-word;
  flex-wrap: nowrap;
}
.nav_button_open {
  justify-content: left;
  padding-left: 10px;
  padding-right: 0px;
  width: 125%;
}
.nav_button:active {
  background-color: #02735E;
}

.nav_button_text {
  display: inline-block;
  color: #F5F5F5;
  /* padding-left: 10px; */
  vertical-align: middle;
  font-size: 30px;
  word-wrap: inherit;
}
.nav_button:hover {
  background-color: #03A678;
  /* box-shadow: 0px 5px 5px #014040ab, 0px -5px 5px #014040ab; */
  border-radius: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.l-navbar {
  position: absolute;
  top: 0;
  color: black;
  left: -50%;
  background-color: #02735E;
  transition: all .3s ease-in-out;
  box-shadow: none;
  width: 20rem;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: .5rem 0 .5rem 1.5rem
}

.nav_logo {
  margin-bottom: 2rem
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: .3s
}

.nav_link:hover {
  color: var(--white-color)
}

.nav_icon {
  font-size: 1.25rem
}

.show {
  left: 0
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem)
}

.active {
  color: var(--white-color)
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color)
}

.height-100 {
  height: 100vh
}

@media only screen and (min-width: 800px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
    height: 100%;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
  }

  .header_img {
    width: 40px;
    height: 40px
  }

  .padded {
    padding-left: 500px;
  }

  .header_img img {
    width: 45px
  }

  #nav-parent {
    position: sticky;
    top: 0;
    overflow-x: hidden;
  }

  .l-navbar {
    /* left: 0%; */
    top: 0;
    position: sticky;
    height: 100vh;
    /* z-index: 99999; */
    align-self: flex-start;
    overflow-x: hidden;
  }

  .show {
    width: calc(var(--nav-width) + 156px)
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 188px)
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.unshown {
  max-width: 80px;
  box-shadow: 3px 4px 6px 3px rgba(0, 0, 0, 0.3);
}

.float{
	position:fixed;
	width:90px;
  /* z-index: 999; */
	height:90px;
	bottom:10px;
	right:10px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.548);
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
}

.float:hover {
  text-decoration: none;
}

.my-float{
  position: relative;
  top: 21%;
  left: 0%;
}

.contact-float{
  position:fixed;
  max-width:90%;
  bottom:110px;
  right:5%;
	background-color:#02735E;
	color: white;
	border-radius:15px;
	text-align:center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.548);
  padding: 15px 10px;
  z-index: 10001;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0,0, 0.0);
}
.contact-icon {
  font-style: normal;
  font-size: 22px;
  color: inherit;
}
.contact-badge {
  height: 33px;
  background-color: white;
  border-radius: 15px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 13px;
  font-family: "roboto";
  font-weight: 400;
  vertical-align: middle;
  color:#731702;
  margin-right: 8px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  border-style: none;
}
.contact-badge:hover {
  background-color: #731702;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  color: white;
}

#footer {
  min-height: 80px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#App {
  min-height: 100vh;
}
#main {
  min-height: calc(100vh - 100px);

}

.top-menu{
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 5px 5px #014040ab, 0px -5px 5px #014040ab;
	width:100%;
  padding: .3rem;
  padding-right: 1rem;
  /* z-index: 999; */
	background-color:#02735E;
	color:#FFF;
  border-color: white;
  border-width: 0px;
  border-style: solid;
	text-align:center;
  vertical-align: middle;
  text-decoration: none;
  transition: .3s;
}

.top-menu-content {
  display: flex;
  width: 100%;
  align-items: center;
}
.top-menu-text {
  color: white;
  height: 100%;
  padding: 1rem 1rem;
  vertical-align:middle;
  font-size: 1.4rem;
  font-family: "roboto";
  font-weight: 300;
  margin: auto 0;
  display: inline-block;
  
}
.top-menu-text::before {
  display: inline-block;
  vertical-align: middle;
}
.menu-icon-float{
  position: relative;
  top: 5%;
  left: 0%;
  cursor: pointer;
}
.top-menu-float{
  border-color: white;
  border-width: 1px;
  border-style: none;
	position:fixed;
  
  right: -150%;
  top: 0%;
  bottom: 0%;
	background-color: rgba(1, 64, 64, 0.5);
  backdrop-filter: blur(10px);
	color: white;
	border-radius:0px;
	text-align:center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.548);
  padding: 15px 10px;
  z-index: 150000;
  transition: .5s;
}

.menu-visible {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}

.top-menu-icon {
  font-style: normal;
  font-size: 22px;
  color: inherit;
  vertical-align: middle;
}
.top-menu-badge {
  height: 40px;
  line-height: 40px;
  background-color: white;
  border-radius: 20px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 18px;
  font-family: "roboto";
  font-weight: 400;
  vertical-align: middle;
  color: #731702;
  width: 80%;
  margin-right: 8px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  cursor: pointer;
}

.top-menu-badge:hover {
  background-color: #731702;
  color: white;
}
.Menu-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(115, 115, 115, 0.3);
}
.menu-li {
  text-decoration: none;
  list-style-type: none;
}

@media only screen and (min-width: 800px) {
  .top-menu {
    display: none;
  }
  .contact-float{
    position:fixed;
    max-width:350px;
    bottom:110px;
    right:20px;
  }
}

.menu-close-badge {
  height: 40px;
  line-height: 40px;
  background-color: #F27405;
  border-radius: 20px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 18px;
  font-family: "roboto";
  font-weight: 400;
  vertical-align: middle;
  color: white;
  width: 50%;
  margin-right: 8px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  cursor: pointer;
}

.left-menu-close {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 40px !important;
  background: transparent;
}
.left-menu-close:hover {
  background-color: #03A678;
}
.breadcrumb {
  color: white;
  text-decoration: none;
  background-color: #014040;
  font-family: "roboto";
  font-size: 1.1rem;
  vertical-align: middle;
}
.breadcrumb:hover {
  text-decoration: none;
}
.breadcrumb-span {
  background-color: #03A678;
  padding: .2rem .3rem;
  border-radius: 5px;
}
.Link>.breadcrumb {
  color: white;
  text-decoration: none;
}