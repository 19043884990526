
.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    align-items: left;
    padding: 15px;
}
.home-card-center {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px;
}
.proj-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
    display: inline-block;
}
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.back-icon {
    cursor: pointer;
    color: white;
    font-size:xx-large;
    display: inline-block;
    padding-right: 3%;
}
.pypi-button {
    color: #F27405;
    border-style: solid;
    border-color: #F27405;
    width: 90%;
    font-size: x-large;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 20px;
    text-decoration: none;
    height: 50%;
}
.pypi-button:hover {
    background-color:#F27405;
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.pip-install-display {
    background-color: #F5F5F5;
    border-radius: 20px;
    width: 90%;
    text-align: center;
    position:relative;
    /* white-space: nowrap; */
    overflow: hidden;
    border-width: 2px;
    border-color: #F5F5F5;
    border-style: solid;
    height: 50%;
    display: table;
}
.pip-install-copy-background {
    background-color: #014040;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 50px;
    vertical-align: middle;

}
.copy-icon {
    cursor: pointer;
    color: white;
    font-size:x-large;
    position: absolute;
    top: 50%;
    right: 20%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
    
}
.pip-text {
    font-family: 'consolas';
    font-size: x-large;
    padding-right: 50px;
}
.overview-text {
    padding: 10px;
    font-family: 'roboto';
    color: white;
    font-weight: 300;
    font-size:large;
}
.stats-card-center {
    margin-top: 15px;
    background-color: #f5f5f5;
    border-radius: 25px;
    border-width: 0;
    color: #731702;
    font-family: 'consolas';
    border-style: none;
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 15px;
}

@media only screen and (max-width: 800px) {
    .proj-title {
        font-size: 40px;
    }
  }