.outer-circle {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    margin: 20px;
    display: inline-block;
    background-color: #02735E;
    text-align: center;
    vertical-align: middle;
}

.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}
.quick-action {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 31px;
    border-width: 3px;
    border-color: #F27405;
    border-style: solid;
    text-align: left;
}

.quick-action:hover {
    background-color: #03A678;
    cursor: pointer;
}

.cert-div {
    background-color: lightgray;
    background: lightgray !important;
    margin: 1rem;
    border-radius: 5px;
}

.home-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
}
@media only screen and (max-width: 800px) {
    .home-title {
        font-size: 40px;
    }
    .outer-circle {
        display: none;
    }
    .home-title {
        display: none;
    }
    .home-hr {
        display: none;
    }
  }
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.link-icon {
    color:#F27405;
    font-family: 'roboto';
    font-weight: 100;
    font-size: 32px;
    font-style: normal;
    vertical-align: middle;
}
.link-icon::before {
    vertical-align: middle;
}
.major-caption {
    color: white;
    font-family: 'roboto';
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
}
.major-details {
    color: white;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 18px;
    font-style: normal;
}
.skill-badge {
    height: 22px;
    background-color: #731702;
    padding-right: 8px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;
    vertical-align: middle;
    color: white;
    margin-right: 8px;
}