.project-card {
    margin-top: 25px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}

.project-hr {
    height: 2px;
    border-radius: 1px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.project-title {
    color: white;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 28px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.project-description {
    color: white;
    font-family: 'roboto';
    font-size: 14px;
    font-weight: 300;
}
.skill-badge {
    height: 22px;
    background-color: #731702;
    padding-right: 8px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;
    vertical-align: middle;
    color: white;
    margin-right: 8px;
}
.project-details-button {
    color: white;
    border-style: solid;
    border-color: #F27405;
    width: 50%;
    font-size: 20px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 20px;
}
.project-details-button:hover {
    background-color:#F27405;
    color: white;
    cursor: pointer;
}