
.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}
.doc-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
    display: inline;
}
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.document {
    border-color: white;
    border-width: 1px;
    width: 100%;
    border-style: solid;
    background-color: #014040;
    border-radius: 15px;
    padding: 15px 10px;
    color: white;
    vertical-align: middle;
    margin-bottom: 15px;
}
.document-title {
    vertical-align: middle;
    color: inherit;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 30px;
}
.download-button {
    display: inline;
    font-size: 20px;
    float: right;
    margin-top: 8px;
    background-color:#014040;
    border-radius: 10px;
    border-style: none;
    color:#F27405;
    cursor: pointer;
    outline: none;
    transition: .4s;
}
.download-button:focus {
    outline: none;
}
.download-button-active {
    border-color:#F27405;
    border-width: 2px;
    border-style: solid;
}
.back-icon {
    cursor: pointer;
    color: white;
    font-size:xx-large;
    display: inline-block;
    padding-right: 3%;
}
@media only screen and (max-width: 800px) {
    .doc-title {
        font-size: 40px;
    }
  }