.experience_header {
    font-family: 'roboto';
    font-size: 18px;
    font-weight: 400;
    color: white;
    margin-bottom: 5px;
    padding-bottom: 5px;
}
.experience-location {
    font-family: 'roboto';
    font-size: 14px;
    font-weight: 300;
    color: white;
    padding-bottom: 10px;
}
.experience-blurb {
    font-family: 'roboto';
    font-size: 16px;
    font-weight: 400;
    color: white;
    font-style: normal;
}
.experience-date {
        font-family: 'roboto';
        font-size: 18px;
        font-weight: 400;
        color: #7dffda;
}

.tag-badge {
    height: 22px;
    background-color: #03A678;
    padding-right: 8px;
    border-radius: 12px;
    margin-right: 5px;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;
    vertical-align: middle;
    color: white;
    margin-right: 8px;
}