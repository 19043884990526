body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #014040 !important;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  min-height: 100%;
  background-color: #014040;
}

@font-face {
font-family: "uicons-regular-rounded";
src: url(/static/media/uicons-regular-rounded.b5dfa57a.eot#iefix) format("embedded-opentype"),
url(/static/media/uicons-regular-rounded.4007ed1c.woff2) format("woff2"),
url(/static/media/uicons-regular-rounded.60238b47.woff) format("woff");
}

    i[class^="fi-rr-"]:before, i[class*=" fi-rr-"]:before, span[class^="fi-rr-"]:before, span[class*="fi-rr-"]:before {
font-family: uicons-regular-rounded !important;
font-style: normal;
font-weight: normal !important;
font-feature-settings: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-rr-add-document:before {
    content: "\F101";
    }
        .fi-rr-add-folder:before {
    content: "\F102";
    }
        .fi-rr-add:before {
    content: "\F103";
    }
        .fi-rr-address-book:before {
    content: "\F104";
    }
        .fi-rr-alarm-clock:before {
    content: "\F105";
    }
        .fi-rr-align-center:before {
    content: "\F106";
    }
        .fi-rr-align-justify:before {
    content: "\F107";
    }
        .fi-rr-align-left:before {
    content: "\F108";
    }
        .fi-rr-align-right:before {
    content: "\F109";
    }
        .fi-rr-ambulance:before {
    content: "\F10A";
    }
        .fi-rr-angle-circle-down:before {
    content: "\F10B";
    }
        .fi-rr-angle-circle-left:before {
    content: "\F10C";
    }
        .fi-rr-angle-circle-right:before {
    content: "\F10D";
    }
        .fi-rr-angle-circle-up:before {
    content: "\F10E";
    }
        .fi-rr-angle-double-left:before {
    content: "\F10F";
    }
        .fi-rr-angle-double-right:before {
    content: "\F110";
    }
        .fi-rr-angle-double-small-down:before {
    content: "\F111";
    }
        .fi-rr-angle-double-small-left:before {
    content: "\F112";
    }
        .fi-rr-angle-double-small-right:before {
    content: "\F113";
    }
        .fi-rr-angle-double-small-up:before {
    content: "\F114";
    }
        .fi-rr-angle-down:before {
    content: "\F115";
    }
        .fi-rr-angle-left:before {
    content: "\F116";
    }
        .fi-rr-angle-right:before {
    content: "\F117";
    }
        .fi-rr-angle-small-down:before {
    content: "\F118";
    }
        .fi-rr-angle-small-left:before {
    content: "\F119";
    }
        .fi-rr-angle-small-right:before {
    content: "\F11A";
    }
        .fi-rr-angle-small-up:before {
    content: "\F11B";
    }
        .fi-rr-angle-square-down:before {
    content: "\F11C";
    }
        .fi-rr-angle-square-left:before {
    content: "\F11D";
    }
        .fi-rr-angle-square-right:before {
    content: "\F11E";
    }
        .fi-rr-angle-square-up:before {
    content: "\F11F";
    }
        .fi-rr-angle-up:before {
    content: "\F120";
    }
        .fi-rr-angry:before {
    content: "\F121";
    }
        .fi-rr-apple-whole:before {
    content: "\F122";
    }
        .fi-rr-apps-add:before {
    content: "\F123";
    }
        .fi-rr-apps-delete:before {
    content: "\F124";
    }
        .fi-rr-apps-sort:before {
    content: "\F125";
    }
        .fi-rr-apps:before {
    content: "\F126";
    }
        .fi-rr-archive:before {
    content: "\F127";
    }
        .fi-rr-arrow-alt-circle-down:before {
    content: "\F128";
    }
        .fi-rr-arrow-alt-circle-left:before {
    content: "\F129";
    }
        .fi-rr-arrow-alt-circle-right:before {
    content: "\F12A";
    }
        .fi-rr-arrow-alt-circle-up:before {
    content: "\F12B";
    }
        .fi-rr-arrow-alt-down:before {
    content: "\F12C";
    }
        .fi-rr-arrow-alt-from-bottom:before {
    content: "\F12D";
    }
        .fi-rr-arrow-alt-from-left:before {
    content: "\F12E";
    }
        .fi-rr-arrow-alt-from-right:before {
    content: "\F12F";
    }
        .fi-rr-arrow-alt-from-top:before {
    content: "\F130";
    }
        .fi-rr-arrow-alt-left:before {
    content: "\F131";
    }
        .fi-rr-arrow-alt-right:before {
    content: "\F132";
    }
        .fi-rr-arrow-alt-square-down:before {
    content: "\F133";
    }
        .fi-rr-arrow-alt-square-left:before {
    content: "\F134";
    }
        .fi-rr-arrow-alt-square-right:before {
    content: "\F135";
    }
        .fi-rr-arrow-alt-square-up:before {
    content: "\F136";
    }
        .fi-rr-arrow-alt-to-bottom:before {
    content: "\F137";
    }
        .fi-rr-arrow-alt-to-left:before {
    content: "\F138";
    }
        .fi-rr-arrow-alt-to-right:before {
    content: "\F139";
    }
        .fi-rr-arrow-alt-to-top:before {
    content: "\F13A";
    }
        .fi-rr-arrow-alt-up:before {
    content: "\F13B";
    }
        .fi-rr-arrow-circle-down:before {
    content: "\F13C";
    }
        .fi-rr-arrow-circle-left:before {
    content: "\F13D";
    }
        .fi-rr-arrow-circle-right:before {
    content: "\F13E";
    }
        .fi-rr-arrow-circle-up:before {
    content: "\F13F";
    }
        .fi-rr-arrow-down:before {
    content: "\F140";
    }
        .fi-rr-arrow-from-bottom:before {
    content: "\F141";
    }
        .fi-rr-arrow-from-left:before {
    content: "\F142";
    }
        .fi-rr-arrow-from-right:before {
    content: "\F143";
    }
        .fi-rr-arrow-from-top:before {
    content: "\F144";
    }
        .fi-rr-arrow-left:before {
    content: "\F145";
    }
        .fi-rr-arrow-right:before {
    content: "\F146";
    }
        .fi-rr-arrow-small-down:before {
    content: "\F147";
    }
        .fi-rr-arrow-small-left:before {
    content: "\F148";
    }
        .fi-rr-arrow-small-right:before {
    content: "\F149";
    }
        .fi-rr-arrow-small-up:before {
    content: "\F14A";
    }
        .fi-rr-arrow-square-down:before {
    content: "\F14B";
    }
        .fi-rr-arrow-square-left:before {
    content: "\F14C";
    }
        .fi-rr-arrow-square-right:before {
    content: "\F14D";
    }
        .fi-rr-arrow-square-up:before {
    content: "\F14E";
    }
        .fi-rr-arrow-to-bottom:before {
    content: "\F14F";
    }
        .fi-rr-arrow-to-left:before {
    content: "\F150";
    }
        .fi-rr-arrow-to-right:before {
    content: "\F151";
    }
        .fi-rr-arrow-to-top:before {
    content: "\F152";
    }
        .fi-rr-arrow-up:before {
    content: "\F153";
    }
        .fi-rr-arrows-alt-h:before {
    content: "\F154";
    }
        .fi-rr-arrows-alt-v:before {
    content: "\F155";
    }
        .fi-rr-arrows-alt:before {
    content: "\F156";
    }
        .fi-rr-arrows-h-copy:before {
    content: "\F157";
    }
        .fi-rr-arrows-h:before {
    content: "\F158";
    }
        .fi-rr-arrows:before {
    content: "\F159";
    }
        .fi-rr-assept-document:before {
    content: "\F15A";
    }
        .fi-rr-assistive-listening-systems:before {
    content: "\F15B";
    }
        .fi-rr-asterik:before {
    content: "\F15C";
    }
        .fi-rr-at:before {
    content: "\F15D";
    }
        .fi-rr-aubergine:before {
    content: "\F15E";
    }
        .fi-rr-avocado:before {
    content: "\F15F";
    }
        .fi-rr-baby-carriage:before {
    content: "\F160";
    }
        .fi-rr-backpack:before {
    content: "\F161";
    }
        .fi-rr-bacon:before {
    content: "\F162";
    }
        .fi-rr-badge:before {
    content: "\F163";
    }
        .fi-rr-balloons:before {
    content: "\F164";
    }
        .fi-rr-ban:before {
    content: "\F165";
    }
        .fi-rr-band-aid:before {
    content: "\F166";
    }
        .fi-rr-bank:before {
    content: "\F167";
    }
        .fi-rr-barber-shop:before {
    content: "\F168";
    }
        .fi-rr-baseball-alt:before {
    content: "\F169";
    }
        .fi-rr-baseball:before {
    content: "\F16A";
    }
        .fi-rr-basketball-hoop:before {
    content: "\F16B";
    }
        .fi-rr-basketball:before {
    content: "\F16C";
    }
        .fi-rr-bed:before {
    content: "\F16D";
    }
        .fi-rr-beer:before {
    content: "\F16E";
    }
        .fi-rr-bell-ring:before {
    content: "\F16F";
    }
        .fi-rr-bell-school:before {
    content: "\F170";
    }
        .fi-rr-bell:before {
    content: "\F171";
    }
        .fi-rr-bike:before {
    content: "\F172";
    }
        .fi-rr-biking-mountain:before {
    content: "\F173";
    }
        .fi-rr-biking:before {
    content: "\F174";
    }
        .fi-rr-billiard:before {
    content: "\F175";
    }
        .fi-rr-bold:before {
    content: "\F176";
    }
        .fi-rr-bolt:before {
    content: "\F177";
    }
        .fi-rr-book-alt:before {
    content: "\F178";
    }
        .fi-rr-book:before {
    content: "\F179";
    }
        .fi-rr-bookmark:before {
    content: "\F17A";
    }
        .fi-rr-bottle:before {
    content: "\F17B";
    }
        .fi-rr-bow-arrow:before {
    content: "\F17C";
    }
        .fi-rr-bowling-ball:before {
    content: "\F17D";
    }
        .fi-rr-bowling-pins:before {
    content: "\F17E";
    }
        .fi-rr-bowling:before {
    content: "\F17F";
    }
        .fi-rr-box-alt:before {
    content: "\F180";
    }
        .fi-rr-box:before {
    content: "\F181";
    }
        .fi-rr-boxing-glove:before {
    content: "\F182";
    }
        .fi-rr-braille:before {
    content: "\F183";
    }
        .fi-rr-bread-slice:before {
    content: "\F184";
    }
        .fi-rr-bread:before {
    content: "\F185";
    }
        .fi-rr-briefcase:before {
    content: "\F186";
    }
        .fi-rr-broccoli:before {
    content: "\F187";
    }
        .fi-rr-broom:before {
    content: "\F188";
    }
        .fi-rr-browser:before {
    content: "\F189";
    }
        .fi-rr-brush:before {
    content: "\F18A";
    }
        .fi-rr-bug:before {
    content: "\F18B";
    }
        .fi-rr-building:before {
    content: "\F18C";
    }
        .fi-rr-bulb:before {
    content: "\F18D";
    }
        .fi-rr-burrito:before {
    content: "\F18E";
    }
        .fi-rr-bus-alt:before {
    content: "\F18F";
    }
        .fi-rr-bus:before {
    content: "\F190";
    }
        .fi-rr-butterfly:before {
    content: "\F191";
    }
        .fi-rr-cake-birthday:before {
    content: "\F192";
    }
        .fi-rr-cake-wedding:before {
    content: "\F193";
    }
        .fi-rr-calculator:before {
    content: "\F194";
    }
        .fi-rr-calendar:before {
    content: "\F195";
    }
        .fi-rr-call-history:before {
    content: "\F196";
    }
        .fi-rr-call-incoming:before {
    content: "\F197";
    }
        .fi-rr-call-missed:before {
    content: "\F198";
    }
        .fi-rr-call-outgoing:before {
    content: "\F199";
    }
        .fi-rr-camera:before {
    content: "\F19A";
    }
        .fi-rr-camping:before {
    content: "\F19B";
    }
        .fi-rr-candy-alt:before {
    content: "\F19C";
    }
        .fi-rr-candy:before {
    content: "\F19D";
    }
        .fi-rr-canned-food:before {
    content: "\F19E";
    }
        .fi-rr-car-alt:before {
    content: "\F19F";
    }
        .fi-rr-car-battery:before {
    content: "\F1A0";
    }
        .fi-rr-car-building:before {
    content: "\F1A1";
    }
        .fi-rr-car-bump:before {
    content: "\F1A2";
    }
        .fi-rr-car-bus:before {
    content: "\F1A3";
    }
        .fi-rr-car-crash:before {
    content: "\F1A4";
    }
        .fi-rr-car-garage:before {
    content: "\F1A5";
    }
        .fi-rr-car-mechanic:before {
    content: "\F1A6";
    }
        .fi-rr-car-side:before {
    content: "\F1A7";
    }
        .fi-rr-car-tilt:before {
    content: "\F1A8";
    }
        .fi-rr-car-wash:before {
    content: "\F1A9";
    }
        .fi-rr-car:before {
    content: "\F1AA";
    }
        .fi-rr-caravan-alt:before {
    content: "\F1AB";
    }
        .fi-rr-caravan:before {
    content: "\F1AC";
    }
        .fi-rr-caret-circle-down:before {
    content: "\F1AD";
    }
        .fi-rr-caret-circle-right:before {
    content: "\F1AE";
    }
        .fi-rr-caret-circle-up:before {
    content: "\F1AF";
    }
        .fi-rr-caret-down:before {
    content: "\F1B0";
    }
        .fi-rr-caret-left:before {
    content: "\F1B1";
    }
        .fi-rr-caret-quare-up:before {
    content: "\F1B2";
    }
        .fi-rr-caret-right:before {
    content: "\F1B3";
    }
        .fi-rr-caret-square-down:before {
    content: "\F1B4";
    }
        .fi-rr-caret-square-left_1:before {
    content: "\F1B5";
    }
        .fi-rr-caret-square-left:before {
    content: "\F1B6";
    }
        .fi-rr-caret-square-right:before {
    content: "\F1B7";
    }
        .fi-rr-caret-up:before {
    content: "\F1B8";
    }
        .fi-rr-carrot:before {
    content: "\F1B9";
    }
        .fi-rr-cars:before {
    content: "\F1BA";
    }
        .fi-rr-charging-station:before {
    content: "\F1BB";
    }
        .fi-rr-chart-connected:before {
    content: "\F1BC";
    }
        .fi-rr-chart-histogram:before {
    content: "\F1BD";
    }
        .fi-rr-chart-network:before {
    content: "\F1BE";
    }
        .fi-rr-chart-pie-alt:before {
    content: "\F1BF";
    }
        .fi-rr-chart-pie:before {
    content: "\F1C0";
    }
        .fi-rr-chart-pyramid:before {
    content: "\F1C1";
    }
        .fi-rr-chart-set-theory:before {
    content: "\F1C2";
    }
        .fi-rr-chart-tree:before {
    content: "\F1C3";
    }
        .fi-rr-chat-arrow-down:before {
    content: "\F1C4";
    }
        .fi-rr-chat-arrow-grow:before {
    content: "\F1C5";
    }
        .fi-rr-check:before {
    content: "\F1C6";
    }
        .fi-rr-checkbox:before {
    content: "\F1C7";
    }
        .fi-rr-cheese:before {
    content: "\F1C8";
    }
        .fi-rr-cherry:before {
    content: "\F1C9";
    }
        .fi-rr-chess-piece:before {
    content: "\F1CA";
    }
        .fi-rr-chess-bishop:before {
    content: "\F1CB";
    }
        .fi-rr-chess-board:before {
    content: "\F1CC";
    }
        .fi-rr-chess-clock-alt:before {
    content: "\F1CD";
    }
        .fi-rr-chess-clock:before {
    content: "\F1CE";
    }
        .fi-rr-chess-king-alt:before {
    content: "\F1CF";
    }
        .fi-rr-chess-king:before {
    content: "\F1D0";
    }
        .fi-rr-chess-knight-alt:before {
    content: "\F1D1";
    }
        .fi-rr-chess-knight:before {
    content: "\F1D2";
    }
        .fi-rr-chess-pawn-alt:before {
    content: "\F1D3";
    }
        .fi-rr-chess-pawn:before {
    content: "\F1D4";
    }
        .fi-rr-chess-queen-alt:before {
    content: "\F1D5";
    }
        .fi-rr-chess-queen:before {
    content: "\F1D6";
    }
        .fi-rr-chess-rook-alt:before {
    content: "\F1D7";
    }
        .fi-rr-chess-rook:before {
    content: "\F1D8";
    }
        .fi-rr-chess:before {
    content: "\F1D9";
    }
        .fi-rr-chevron-double-down:before {
    content: "\F1DA";
    }
        .fi-rr-chevron-double-up:before {
    content: "\F1DB";
    }
        .fi-rr-child-head:before {
    content: "\F1DC";
    }
        .fi-rr-chocolate:before {
    content: "\F1DD";
    }
        .fi-rr-circle-small:before {
    content: "\F1DE";
    }
        .fi-rr-circle:before {
    content: "\F1DF";
    }
        .fi-rr-clip:before {
    content: "\F1E0";
    }
        .fi-rr-clock:before {
    content: "\F1E1";
    }
        .fi-rr-cloud-upload:before {
    content: "\F1E2";
    }
        .fi-rr-cloud-check:before {
    content: "\F1E3";
    }
        .fi-rr-cloud-disabled:before {
    content: "\F1E4";
    }
        .fi-rr-cloud-download-alt:before {
    content: "\F1E5";
    }
        .fi-rr-cloud-download:before {
    content: "\F1E6";
    }
        .fi-rr-cloud-drizzle:before {
    content: "\F1E7";
    }
        .fi-rr-cloud-hail-mixed:before {
    content: "\F1E8";
    }
        .fi-rr-cloud-hail:before {
    content: "\F1E9";
    }
        .fi-rr-cloud-moon-rain:before {
    content: "\F1EA";
    }
        .fi-rr-cloud-moon:before {
    content: "\F1EB";
    }
        .fi-rr-cloud-rain:before {
    content: "\F1EC";
    }
        .fi-rr-cloud-rainbow:before {
    content: "\F1ED";
    }
        .fi-rr-cloud-share:before {
    content: "\F1EE";
    }
        .fi-rr-cloud-showers-heavy:before {
    content: "\F1EF";
    }
        .fi-rr-cloud-showers:before {
    content: "\F1F0";
    }
        .fi-rr-cloud-sleet:before {
    content: "\F1F1";
    }
        .fi-rr-cloud-snow:before {
    content: "\F1F2";
    }
        .fi-rr-cloud-sun-rain:before {
    content: "\F1F3";
    }
        .fi-rr-cloud-sun:before {
    content: "\F1F4";
    }
        .fi-rr-cloud-upload-alt:before {
    content: "\F1F5";
    }
        .fi-rr-cloud:before {
    content: "\F1F6";
    }
        .fi-rr-clouds-moon:before {
    content: "\F1F7";
    }
        .fi-rr-clouds-sun:before {
    content: "\F1F8";
    }
        .fi-rr-clouds:before {
    content: "\F1F9";
    }
        .fi-rr-club:before {
    content: "\F1FA";
    }
        .fi-rr-cocktail-alt:before {
    content: "\F1FB";
    }
        .fi-rr-cocktail:before {
    content: "\F1FC";
    }
        .fi-rr-coffee-pot:before {
    content: "\F1FD";
    }
        .fi-rr-coffee:before {
    content: "\F1FE";
    }
        .fi-rr-comment-alt:before {
    content: "\F1FF";
    }
        .fi-rr-comment-check:before {
    content: "\F200";
    }
        .fi-rr-comment-heart:before {
    content: "\F201";
    }
        .fi-rr-comment-info:before {
    content: "\F202";
    }
        .fi-rr-comment-user:before {
    content: "\F203";
    }
        .fi-rr-comment:before {
    content: "\F204";
    }
        .fi-rr-comments:before {
    content: "\F205";
    }
        .fi-rr-compress-alt:before {
    content: "\F206";
    }
        .fi-rr-compress:before {
    content: "\F207";
    }
        .fi-rr-computer:before {
    content: "\F208";
    }
        .fi-rr-confetti:before {
    content: "\F209";
    }
        .fi-rr-cookie:before {
    content: "\F20A";
    }
        .fi-rr-copy-alt:before {
    content: "\F20B";
    }
        .fi-rr-copy:before {
    content: "\F20C";
    }
        .fi-rr-copyright:before {
    content: "\F20D";
    }
        .fi-rr-corn:before {
    content: "\F20E";
    }
        .fi-rr-cow:before {
    content: "\F20F";
    }
        .fi-rr-cream:before {
    content: "\F210";
    }
        .fi-rr-credit-card:before {
    content: "\F211";
    }
        .fi-rr-cricket:before {
    content: "\F212";
    }
        .fi-rr-croissant:before {
    content: "\F213";
    }
        .fi-rr-cross-circle:before {
    content: "\F214";
    }
        .fi-rr-cross-small:before {
    content: "\F215";
    }
        .fi-rr-cross:before {
    content: "\F216";
    }
        .fi-rr-crown:before {
    content: "\F217";
    }
        .fi-rr-cube:before {
    content: "\F218";
    }
        .fi-rr-cupcake:before {
    content: "\F219";
    }
        .fi-rr-curling:before {
    content: "\F21A";
    }
        .fi-rr-cursor-finger:before {
    content: "\F21B";
    }
        .fi-rr-cursor-plus:before {
    content: "\F21C";
    }
        .fi-rr-cursor-text-alt:before {
    content: "\F21D";
    }
        .fi-rr-cursor-text:before {
    content: "\F21E";
    }
        .fi-rr-cursor:before {
    content: "\F21F";
    }
        .fi-rr-dart:before {
    content: "\F220";
    }
        .fi-rr-dashboard:before {
    content: "\F221";
    }
        .fi-rr-data-transfer:before {
    content: "\F222";
    }
        .fi-rr-database:before {
    content: "\F223";
    }
        .fi-rr-delete-document:before {
    content: "\F224";
    }
        .fi-rr-delete-user:before {
    content: "\F225";
    }
        .fi-rr-delete:before {
    content: "\F226";
    }
        .fi-rr-dewpoint:before {
    content: "\F227";
    }
        .fi-rr-diamond:before {
    content: "\F228";
    }
        .fi-rr-dice-alt:before {
    content: "\F229";
    }
        .fi-rr-dice-d10:before {
    content: "\F22A";
    }
        .fi-rr-dice-d12:before {
    content: "\F22B";
    }
        .fi-rr-dice-d20:before {
    content: "\F22C";
    }
        .fi-rr-dice-d4:before {
    content: "\F22D";
    }
        .fi-rr-dice-d6:before {
    content: "\F22E";
    }
        .fi-rr-dice-d8:before {
    content: "\F22F";
    }
        .fi-rr-dice-four:before {
    content: "\F230";
    }
        .fi-rr-dice-one:before {
    content: "\F231";
    }
        .fi-rr-dice-six:before {
    content: "\F232";
    }
        .fi-rr-dice-three:before {
    content: "\F233";
    }
        .fi-rr-dice-two:before {
    content: "\F234";
    }
        .fi-rr-dice:before {
    content: "\F235";
    }
        .fi-rr-diploma:before {
    content: "\F236";
    }
        .fi-rr-disco-ball:before {
    content: "\F237";
    }
        .fi-rr-disk:before {
    content: "\F238";
    }
        .fi-rr-dizzy:before {
    content: "\F239";
    }
        .fi-rr-doctor:before {
    content: "\F23A";
    }
        .fi-rr-document-signed:before {
    content: "\F23B";
    }
        .fi-rr-document:before {
    content: "\F23C";
    }
        .fi-rr-dollar:before {
    content: "\F23D";
    }
        .fi-rr-download:before {
    content: "\F23E";
    }
        .fi-rr-dreidel:before {
    content: "\F23F";
    }
        .fi-rr-drink-alt:before {
    content: "\F240";
    }
        .fi-rr-drumstick:before {
    content: "\F241";
    }
        .fi-rr-duplicate:before {
    content: "\F242";
    }
        .fi-rr-e-learning:before {
    content: "\F243";
    }
        .fi-rr-earnings:before {
    content: "\F244";
    }
        .fi-rr-eclipse-alt:before {
    content: "\F245";
    }
        .fi-rr-eclipse:before {
    content: "\F246";
    }
        .fi-rr-edit-alt:before {
    content: "\F247";
    }
        .fi-rr-edit:before {
    content: "\F248";
    }
        .fi-rr-egg-fried:before {
    content: "\F249";
    }
        .fi-rr-egg:before {
    content: "\F24A";
    }
        .fi-rr-engine-warning:before {
    content: "\F24B";
    }
        .fi-rr-envelope-ban:before {
    content: "\F24C";
    }
        .fi-rr-envelope-download:before {
    content: "\F24D";
    }
        .fi-rr-envelope-marker:before {
    content: "\F24E";
    }
        .fi-rr-envelope-open:before {
    content: "\F24F";
    }
        .fi-rr-envelope-plus:before {
    content: "\F250";
    }
        .fi-rr-envelope:before {
    content: "\F251";
    }
        .fi-rr-euro:before {
    content: "\F252";
    }
        .fi-rr-exchange-alt:before {
    content: "\F253";
    }
        .fi-rr-exchange:before {
    content: "\F254";
    }
        .fi-rr-exclamation:before {
    content: "\F255";
    }
        .fi-rr-expand-arrows-alt:before {
    content: "\F256";
    }
        .fi-rr-expand-arrows:before {
    content: "\F257";
    }
        .fi-rr-expand:before {
    content: "\F258";
    }
        .fi-rr-eye-crossed:before {
    content: "\F259";
    }
        .fi-rr-eye-dropper:before {
    content: "\F25A";
    }
        .fi-rr-eye:before {
    content: "\F25B";
    }
        .fi-rr-feather:before {
    content: "\F25C";
    }
        .fi-rr-ferris-wheel:before {
    content: "\F25D";
    }
        .fi-rr-field-hockey:before {
    content: "\F25E";
    }
        .fi-rr-fighter-jet:before {
    content: "\F25F";
    }
        .fi-rr-file-ai:before {
    content: "\F260";
    }
        .fi-rr-file-eps:before {
    content: "\F261";
    }
        .fi-rr-file-psd:before {
    content: "\F262";
    }
        .fi-rr-file:before {
    content: "\F263";
    }
        .fi-rr-fill:before {
    content: "\F264";
    }
        .fi-rr-film:before {
    content: "\F265";
    }
        .fi-rr-filter:before {
    content: "\F266";
    }
        .fi-rr-fingerprint:before {
    content: "\F267";
    }
        .fi-rr-fish:before {
    content: "\F268";
    }
        .fi-rr-flag:before {
    content: "\F269";
    }
        .fi-rr-flame:before {
    content: "\F26A";
    }
        .fi-rr-flip-horizontal:before {
    content: "\F26B";
    }
        .fi-rr-flower-bouquet:before {
    content: "\F26C";
    }
        .fi-rr-flower-tulip:before {
    content: "\F26D";
    }
        .fi-rr-flower:before {
    content: "\F26E";
    }
        .fi-rr-flushed:before {
    content: "\F26F";
    }
        .fi-rr-fog:before {
    content: "\F270";
    }
        .fi-rr-folder:before {
    content: "\F271";
    }
        .fi-rr-following:before {
    content: "\F272";
    }
        .fi-rr-football:before {
    content: "\F273";
    }
        .fi-rr-fork:before {
    content: "\F274";
    }
        .fi-rr-form:before {
    content: "\F275";
    }
        .fi-rr-forward:before {
    content: "\F276";
    }
        .fi-rr-fox:before {
    content: "\F277";
    }
        .fi-rr-french-fries:before {
    content: "\F278";
    }
        .fi-rr-frown:before {
    content: "\F279";
    }
        .fi-rr-ftp:before {
    content: "\F27A";
    }
        .fi-rr-gallery:before {
    content: "\F27B";
    }
        .fi-rr-game-board-alt:before {
    content: "\F27C";
    }
        .fi-rr-gamepad:before {
    content: "\F27D";
    }
        .fi-rr-garage-car:before {
    content: "\F27E";
    }
        .fi-rr-garage-open:before {
    content: "\F27F";
    }
        .fi-rr-garage:before {
    content: "\F280";
    }
        .fi-rr-garlic:before {
    content: "\F281";
    }
        .fi-rr-gas-pump-alt:before {
    content: "\F282";
    }
        .fi-rr-gas-pump-slash:before {
    content: "\F283";
    }
        .fi-rr-gas-pump:before {
    content: "\F284";
    }
        .fi-rr-gem:before {
    content: "\F285";
    }
        .fi-rr-gif:before {
    content: "\F286";
    }
        .fi-rr-gift:before {
    content: "\F287";
    }
        .fi-rr-gingerbread-man:before {
    content: "\F288";
    }
        .fi-rr-glass-cheers:before {
    content: "\F289";
    }
        .fi-rr-glass:before {
    content: "\F28A";
    }
        .fi-rr-glasses:before {
    content: "\F28B";
    }
        .fi-rr-globe-alt:before {
    content: "\F28C";
    }
        .fi-rr-globe:before {
    content: "\F28D";
    }
        .fi-rr-golf-ball:before {
    content: "\F28E";
    }
        .fi-rr-golf-club:before {
    content: "\F28F";
    }
        .fi-rr-golf:before {
    content: "\F290";
    }
        .fi-rr-graduation-cap:before {
    content: "\F291";
    }
        .fi-rr-grape:before {
    content: "\F292";
    }
        .fi-rr-graphic-tablet:before {
    content: "\F293";
    }
        .fi-rr-grid-alt:before {
    content: "\F294";
    }
        .fi-rr-grid:before {
    content: "\F295";
    }
        .fi-rr-grill:before {
    content: "\F296";
    }
        .fi-rr-grimace:before {
    content: "\F297";
    }
        .fi-rr-grin-alt:before {
    content: "\F298";
    }
        .fi-rr-grin-beam-sweat:before {
    content: "\F299";
    }
        .fi-rr-grin-beam:before {
    content: "\F29A";
    }
        .fi-rr-grin-hearts:before {
    content: "\F29B";
    }
        .fi-rr-grin-squint-tears:before {
    content: "\F29C";
    }
        .fi-rr-grin-squint:before {
    content: "\F29D";
    }
        .fi-rr-grin-stars:before {
    content: "\F29E";
    }
        .fi-rr-grin-tears:before {
    content: "\F29F";
    }
        .fi-rr-grin-tongue-squint:before {
    content: "\F2A0";
    }
        .fi-rr-grin-tongue-wink:before {
    content: "\F2A1";
    }
        .fi-rr-grin-tongue:before {
    content: "\F2A2";
    }
        .fi-rr-grin-wink:before {
    content: "\F2A3";
    }
        .fi-rr-grin:before {
    content: "\F2A4";
    }
        .fi-rr-guitar:before {
    content: "\F2A5";
    }
        .fi-rr-gym:before {
    content: "\F2A6";
    }
        .fi-rr-hamburger-soda:before {
    content: "\F2A7";
    }
        .fi-rr-hamburger:before {
    content: "\F2A8";
    }
        .fi-rr-hand-holding-heart:before {
    content: "\F2A9";
    }
        .fi-rr-hastag:before {
    content: "\F2AA";
    }
        .fi-rr-hat-birthday:before {
    content: "\F2AB";
    }
        .fi-rr-hat-chef:before {
    content: "\F2AC";
    }
        .fi-rr-head-side-thinking:before {
    content: "\F2AD";
    }
        .fi-rr-headphones:before {
    content: "\F2AE";
    }
        .fi-rr-headset:before {
    content: "\F2AF";
    }
        .fi-rr-heart-arrow:before {
    content: "\F2B0";
    }
        .fi-rr-heart:before {
    content: "\F2B1";
    }
        .fi-rr-heat:before {
    content: "\F2B2";
    }
        .fi-rr-helicopter-side:before {
    content: "\F2B3";
    }
        .fi-rr-hiking:before {
    content: "\F2B4";
    }
        .fi-rr-hockey-mask:before {
    content: "\F2B5";
    }
        .fi-rr-hockey-puck:before {
    content: "\F2B6";
    }
        .fi-rr-hockey-sticks:before {
    content: "\F2B7";
    }
        .fi-rr-home-location-alt:before {
    content: "\F2B8";
    }
        .fi-rr-home-location:before {
    content: "\F2B9";
    }
        .fi-rr-home:before {
    content: "\F2BA";
    }
        .fi-rr-hotdog:before {
    content: "\F2BB";
    }
        .fi-rr-hourglass-end:before {
    content: "\F2BC";
    }
        .fi-rr-hourglass:before {
    content: "\F2BD";
    }
        .fi-rr-house-flood:before {
    content: "\F2BE";
    }
        .fi-rr-humidity:before {
    content: "\F2BF";
    }
        .fi-rr-hurricane:before {
    content: "\F2C0";
    }
        .fi-rr-ice-cream:before {
    content: "\F2C1";
    }
        .fi-rr-ice-skate:before {
    content: "\F2C2";
    }
        .fi-rr-id-badge:before {
    content: "\F2C3";
    }
        .fi-rr-inbox-in:before {
    content: "\F2C4";
    }
        .fi-rr-inbox-out:before {
    content: "\F2C5";
    }
        .fi-rr-inbox:before {
    content: "\F2C6";
    }
        .fi-rr-incognito:before {
    content: "\F2C7";
    }
        .fi-rr-indent:before {
    content: "\F2C8";
    }
        .fi-rr-infinity:before {
    content: "\F2C9";
    }
        .fi-rr-info:before {
    content: "\F2CA";
    }
        .fi-rr-interactive:before {
    content: "\F2CB";
    }
        .fi-rr-interlining:before {
    content: "\F2CC";
    }
        .fi-rr-interrogation:before {
    content: "\F2CD";
    }
        .fi-rr-italic:before {
    content: "\F2CE";
    }
        .fi-rr-jam:before {
    content: "\F2CF";
    }
        .fi-rr-jpg:before {
    content: "\F2D0";
    }
        .fi-rr-key:before {
    content: "\F2D1";
    }
        .fi-rr-keyboard:before {
    content: "\F2D2";
    }
        .fi-rr-kiss-beam:before {
    content: "\F2D3";
    }
        .fi-rr-kiss-wink-heart:before {
    content: "\F2D4";
    }
        .fi-rr-kiss:before {
    content: "\F2D5";
    }
        .fi-rr-kite:before {
    content: "\F2D6";
    }
        .fi-rr-knife:before {
    content: "\F2D7";
    }
        .fi-rr-label:before {
    content: "\F2D8";
    }
        .fi-rr-laptop:before {
    content: "\F2D9";
    }
        .fi-rr-lasso:before {
    content: "\F2DA";
    }
        .fi-rr-laugh-beam:before {
    content: "\F2DB";
    }
        .fi-rr-laugh-squint:before {
    content: "\F2DC";
    }
        .fi-rr-laugh-wink:before {
    content: "\F2DD";
    }
        .fi-rr-laugh:before {
    content: "\F2DE";
    }
        .fi-rr-layers:before {
    content: "\F2DF";
    }
        .fi-rr-layout-fluid:before {
    content: "\F2E0";
    }
        .fi-rr-leaf:before {
    content: "\F2E1";
    }
        .fi-rr-lemon:before {
    content: "\F2E2";
    }
        .fi-rr-letter-case:before {
    content: "\F2E3";
    }
        .fi-rr-lettuce:before {
    content: "\F2E4";
    }
        .fi-rr-level-down-alt:before {
    content: "\F2E5";
    }
        .fi-rr-level-down:before {
    content: "\F2E6";
    }
        .fi-rr-level-up-alt:before {
    content: "\F2E7";
    }
        .fi-rr-level-up:before {
    content: "\F2E8";
    }
        .fi-rr-life-ring:before {
    content: "\F2E9";
    }
        .fi-rr-line-width:before {
    content: "\F2EA";
    }
        .fi-rr-link:before {
    content: "\F2EB";
    }
        .fi-rr-lipstick:before {
    content: "\F2EC";
    }
        .fi-rr-list-check:before {
    content: "\F2ED";
    }
        .fi-rr-list:before {
    content: "\F2EE";
    }
        .fi-rr-loading:before {
    content: "\F2EF";
    }
        .fi-rr-location-alt:before {
    content: "\F2F0";
    }
        .fi-rr-lock-alt:before {
    content: "\F2F1";
    }
        .fi-rr-lock:before {
    content: "\F2F2";
    }
        .fi-rr-luchador:before {
    content: "\F2F3";
    }
        .fi-rr-luggage-rolling:before {
    content: "\F2F4";
    }
        .fi-rr-magic-wand:before {
    content: "\F2F5";
    }
        .fi-rr-makeup-brush:before {
    content: "\F2F6";
    }
        .fi-rr-man-head:before {
    content: "\F2F7";
    }
        .fi-rr-map-marker-cross:before {
    content: "\F2F8";
    }
        .fi-rr-map-marker-home:before {
    content: "\F2F9";
    }
        .fi-rr-map-marker-minus:before {
    content: "\F2FA";
    }
        .fi-rr-map-marker-plus:before {
    content: "\F2FB";
    }
        .fi-rr-map-marker:before {
    content: "\F2FC";
    }
        .fi-rr-map:before {
    content: "\F2FD";
    }
        .fi-rr-marker-time:before {
    content: "\F2FE";
    }
        .fi-rr-marker:before {
    content: "\F2FF";
    }
        .fi-rr-mars-double:before {
    content: "\F300";
    }
        .fi-rr-mars:before {
    content: "\F301";
    }
        .fi-rr-mask-carnival:before {
    content: "\F302";
    }
        .fi-rr-medicine:before {
    content: "\F303";
    }
        .fi-rr-megaphone:before {
    content: "\F304";
    }
        .fi-rr-meh-blank:before {
    content: "\F305";
    }
        .fi-rr-meh-rolling-eyes:before {
    content: "\F306";
    }
        .fi-rr-meh:before {
    content: "\F307";
    }
        .fi-rr-melon:before {
    content: "\F308";
    }
        .fi-rr-menu-burger:before {
    content: "\F309";
    }
        .fi-rr-menu-dots-vertical:before {
    content: "\F30A";
    }
        .fi-rr-menu-dots:before {
    content: "\F30B";
    }
        .fi-rr-meteor:before {
    content: "\F30C";
    }
        .fi-rr-microphone-alt:before {
    content: "\F30D";
    }
        .fi-rr-microphone:before {
    content: "\F30E";
    }
        .fi-rr-minus-small:before {
    content: "\F30F";
    }
        .fi-rr-minus:before {
    content: "\F310";
    }
        .fi-rr-mobile:before {
    content: "\F311";
    }
        .fi-rr-mode-landscape:before {
    content: "\F312";
    }
        .fi-rr-mode-portrait:before {
    content: "\F313";
    }
        .fi-rr-money:before {
    content: "\F314";
    }
        .fi-rr-moon-stars:before {
    content: "\F315";
    }
        .fi-rr-moon:before {
    content: "\F316";
    }
        .fi-rr-motorcycle:before {
    content: "\F317";
    }
        .fi-rr-mountains:before {
    content: "\F318";
    }
        .fi-rr-mouse:before {
    content: "\F319";
    }
        .fi-rr-mug-alt:before {
    content: "\F31A";
    }
        .fi-rr-mug-hot-alt:before {
    content: "\F31B";
    }
        .fi-rr-mug-hot:before {
    content: "\F31C";
    }
        .fi-rr-mug-tea:before {
    content: "\F31D";
    }
        .fi-rr-mug:before {
    content: "\F31E";
    }
        .fi-rr-mushroom:before {
    content: "\F31F";
    }
        .fi-rr-music-file:before {
    content: "\F320";
    }
        .fi-rr-music-alt:before {
    content: "\F321";
    }
        .fi-rr-music:before {
    content: "\F322";
    }
        .fi-rr-navigation:before {
    content: "\F323";
    }
        .fi-rr-network-cloud:before {
    content: "\F324";
    }
        .fi-rr-network:before {
    content: "\F325";
    }
        .fi-rr-noodles:before {
    content: "\F326";
    }
        .fi-rr-notebook:before {
    content: "\F327";
    }
        .fi-rr-oil-can:before {
    content: "\F328";
    }
        .fi-rr-oil-temp:before {
    content: "\F329";
    }
        .fi-rr-olive-oil:before {
    content: "\F32A";
    }
        .fi-rr-olives:before {
    content: "\F32B";
    }
        .fi-rr-onion:before {
    content: "\F32C";
    }
        .fi-rr-opacity:before {
    content: "\F32D";
    }
        .fi-rr-package:before {
    content: "\F32E";
    }
        .fi-rr-paint-brush:before {
    content: "\F32F";
    }
        .fi-rr-palette:before {
    content: "\F330";
    }
        .fi-rr-pan:before {
    content: "\F331";
    }
        .fi-rr-paper-plane:before {
    content: "\F332";
    }
        .fi-rr-password:before {
    content: "\F333";
    }
        .fi-rr-pause:before {
    content: "\F334";
    }
        .fi-rr-paw:before {
    content: "\F335";
    }
        .fi-rr-peach:before {
    content: "\F336";
    }
        .fi-rr-pencil:before {
    content: "\F337";
    }
        .fi-rr-pennant:before {
    content: "\F338";
    }
        .fi-rr-pepper-hot:before {
    content: "\F339";
    }
        .fi-rr-pepper:before {
    content: "\F33A";
    }
        .fi-rr-pharmacy:before {
    content: "\F33B";
    }
        .fi-rr-phone-call:before {
    content: "\F33C";
    }
        .fi-rr-phone-cross:before {
    content: "\F33D";
    }
        .fi-rr-phone-pause:before {
    content: "\F33E";
    }
        .fi-rr-phone-slash:before {
    content: "\F33F";
    }
        .fi-rr-physics:before {
    content: "\F340";
    }
        .fi-rr-picnic:before {
    content: "\F341";
    }
        .fi-rr-picture:before {
    content: "\F342";
    }
        .fi-rr-pie:before {
    content: "\F343";
    }
        .fi-rr-pineapple:before {
    content: "\F344";
    }
        .fi-rr-ping-pong:before {
    content: "\F345";
    }
        .fi-rr-pizza-slice:before {
    content: "\F346";
    }
        .fi-rr-plane-alt:before {
    content: "\F347";
    }
        .fi-rr-plane:before {
    content: "\F348";
    }
        .fi-rr-plate:before {
    content: "\F349";
    }
        .fi-rr-play-alt:before {
    content: "\F34A";
    }
        .fi-rr-play:before {
    content: "\F34B";
    }
        .fi-rr-playing-cards:before {
    content: "\F34C";
    }
        .fi-rr-plus-small:before {
    content: "\F34D";
    }
        .fi-rr-plus:before {
    content: "\F34E";
    }
        .fi-rr-poker-chip:before {
    content: "\F34F";
    }
        .fi-rr-poo:before {
    content: "\F350";
    }
        .fi-rr-popcorn:before {
    content: "\F351";
    }
        .fi-rr-portrait:before {
    content: "\F352";
    }
        .fi-rr-pot:before {
    content: "\F353";
    }
        .fi-rr-pound:before {
    content: "\F354";
    }
        .fi-rr-power:before {
    content: "\F355";
    }
        .fi-rr-presentation:before {
    content: "\F356";
    }
        .fi-rr-print:before {
    content: "\F357";
    }
        .fi-rr-protractor:before {
    content: "\F358";
    }
        .fi-rr-pulse:before {
    content: "\F359";
    }
        .fi-rr-pumpkin:before {
    content: "\F35A";
    }
        .fi-rr-puzzle-piece:before {
    content: "\F35B";
    }
        .fi-rr-pyramid:before {
    content: "\F35C";
    }
        .fi-rr-quote-right:before {
    content: "\F35D";
    }
        .fi-rr-radish:before {
    content: "\F35E";
    }
        .fi-rr-rainbow:before {
    content: "\F35F";
    }
        .fi-rr-raindrops:before {
    content: "\F360";
    }
        .fi-rr-raquet:before {
    content: "\F361";
    }
        .fi-rr-rec:before {
    content: "\F362";
    }
        .fi-rr-receipt:before {
    content: "\F363";
    }
        .fi-rr-record-vinyl:before {
    content: "\F364";
    }
        .fi-rr-rectabgle-vertical:before {
    content: "\F365";
    }
        .fi-rr-rectangle-horizontal:before {
    content: "\F366";
    }
        .fi-rr-rectangle-panoramic:before {
    content: "\F367";
    }
        .fi-rr-recycle:before {
    content: "\F368";
    }
        .fi-rr-redo-alt:before {
    content: "\F369";
    }
        .fi-rr-redo:before {
    content: "\F36A";
    }
        .fi-rr-reflect:before {
    content: "\F36B";
    }
        .fi-rr-refresh:before {
    content: "\F36C";
    }
        .fi-rr-remove-user:before {
    content: "\F36D";
    }
        .fi-rr-reply-all:before {
    content: "\F36E";
    }
        .fi-rr-resize:before {
    content: "\F36F";
    }
        .fi-rr-resources:before {
    content: "\F370";
    }
        .fi-rr-restaurant:before {
    content: "\F371";
    }
        .fi-rr-rewind:before {
    content: "\F372";
    }
        .fi-rr-rhombus:before {
    content: "\F373";
    }
        .fi-rr-rings-wedding:before {
    content: "\F374";
    }
        .fi-rr-road:before {
    content: "\F375";
    }
        .fi-rr-rocket-lunch:before {
    content: "\F376";
    }
        .fi-rr-rocket:before {
    content: "\F377";
    }
        .fi-rr-room-service:before {
    content: "\F378";
    }
        .fi-rr-rotate-right:before {
    content: "\F379";
    }
        .fi-rr-rugby-helmet:before {
    content: "\F37A";
    }
        .fi-rr-rugby:before {
    content: "\F37B";
    }
        .fi-rr-running:before {
    content: "\F37C";
    }
        .fi-rr-rv:before {
    content: "\F37D";
    }
        .fi-rr-sad-cry:before {
    content: "\F37E";
    }
        .fi-rr-sad-tear:before {
    content: "\F37F";
    }
        .fi-rr-sad:before {
    content: "\F380";
    }
        .fi-rr-salad:before {
    content: "\F381";
    }
        .fi-rr-salt-pepper:before {
    content: "\F382";
    }
        .fi-rr-sandwich:before {
    content: "\F383";
    }
        .fi-rr-sauce:before {
    content: "\F384";
    }
        .fi-rr-sausage:before {
    content: "\F385";
    }
        .fi-rr-scale:before {
    content: "\F386";
    }
        .fi-rr-school-bus:before {
    content: "\F387";
    }
        .fi-rr-school:before {
    content: "\F388";
    }
        .fi-rr-scissors:before {
    content: "\F389";
    }
        .fi-rr-screen:before {
    content: "\F38A";
    }
        .fi-rr-search-alt:before {
    content: "\F38B";
    }
        .fi-rr-search-heart:before {
    content: "\F38C";
    }
        .fi-rr-search:before {
    content: "\F38D";
    }
        .fi-rr-settings-sliders:before {
    content: "\F38E";
    }
        .fi-rr-settings:before {
    content: "\F38F";
    }
        .fi-rr-share:before {
    content: "\F390";
    }
        .fi-rr-shield-check:before {
    content: "\F391";
    }
        .fi-rr-shield-exclamation:before {
    content: "\F392";
    }
        .fi-rr-shield-interrogation:before {
    content: "\F393";
    }
        .fi-rr-shield-plus:before {
    content: "\F394";
    }
        .fi-rr-shield:before {
    content: "\F395";
    }
        .fi-rr-ship-side:before {
    content: "\F396";
    }
        .fi-rr-ship:before {
    content: "\F397";
    }
        .fi-rr-shop:before {
    content: "\F398";
    }
        .fi-rr-shopping-bag-add:before {
    content: "\F399";
    }
        .fi-rr-shopping-bag:before {
    content: "\F39A";
    }
        .fi-rr-shopping-cart-add:before {
    content: "\F39B";
    }
        .fi-rr-shopping-cart-check:before {
    content: "\F39C";
    }
        .fi-rr-shopping-cart:before {
    content: "\F39D";
    }
        .fi-rr-shrimp:before {
    content: "\F39E";
    }
        .fi-rr-shuffle:before {
    content: "\F39F";
    }
        .fi-rr-shuttle-van:before {
    content: "\F3A0";
    }
        .fi-rr-shuttlecock:before {
    content: "\F3A1";
    }
        .fi-rr-sign-in-alt:before {
    content: "\F3A2";
    }
        .fi-rr-sign-in:before {
    content: "\F3A3";
    }
        .fi-rr-sign-out-alt:before {
    content: "\F3A4";
    }
        .fi-rr-sign-out:before {
    content: "\F3A5";
    }
        .fi-rr-signal-alt-1:before {
    content: "\F3A6";
    }
        .fi-rr-signal-alt-2:before {
    content: "\F3A7";
    }
        .fi-rr-signal-alt:before {
    content: "\F3A8";
    }
        .fi-rr-skateboard:before {
    content: "\F3A9";
    }
        .fi-rr-skating:before {
    content: "\F3AA";
    }
        .fi-rr-skewer:before {
    content: "\F3AB";
    }
        .fi-rr-ski-jump:before {
    content: "\F3AC";
    }
        .fi-rr-ski-lift:before {
    content: "\F3AD";
    }
        .fi-rr-skiing-nordic:before {
    content: "\F3AE";
    }
        .fi-rr-skiing:before {
    content: "\F3AF";
    }
        .fi-rr-sledding:before {
    content: "\F3B0";
    }
        .fi-rr-sleigh:before {
    content: "\F3B1";
    }
        .fi-rr-smartphone:before {
    content: "\F3B2";
    }
        .fi-rr-smile-beam:before {
    content: "\F3B3";
    }
        .fi-rr-smile-wink:before {
    content: "\F3B4";
    }
        .fi-rr-smile:before {
    content: "\F3B5";
    }
        .fi-rr-smog:before {
    content: "\F3B6";
    }
        .fi-rr-smoke:before {
    content: "\F3B7";
    }
        .fi-rr-snow-blowing:before {
    content: "\F3B8";
    }
        .fi-rr-snowboarding:before {
    content: "\F3B9";
    }
        .fi-rr-snowflake:before {
    content: "\F3BA";
    }
        .fi-rr-snowflakes:before {
    content: "\F3BB";
    }
        .fi-rr-snowmobile:before {
    content: "\F3BC";
    }
        .fi-rr-snowplow:before {
    content: "\F3BD";
    }
        .fi-rr-soap:before {
    content: "\F3BE";
    }
        .fi-rr-sort-alpha-down-alt:before {
    content: "\F3BF";
    }
        .fi-rr-sort-alpha-down:before {
    content: "\F3C0";
    }
        .fi-rr-sort-alpha-up-alt:before {
    content: "\F3C1";
    }
        .fi-rr-sort-alpha-up:before {
    content: "\F3C2";
    }
        .fi-rr-sort-alt:before {
    content: "\F3C3";
    }
        .fi-rr-sort-amount-down-alt:before {
    content: "\F3C4";
    }
        .fi-rr-sort-amount-down:before {
    content: "\F3C5";
    }
        .fi-rr-sort-amount-up-alt:before {
    content: "\F3C6";
    }
        .fi-rr-sort-amount-up:before {
    content: "\F3C7";
    }
        .fi-rr-sort-down:before {
    content: "\F3C8";
    }
        .fi-rr-sort-numeric-down-alt:before {
    content: "\F3C9";
    }
        .fi-rr-sort-numeric-down:before {
    content: "\F3CA";
    }
        .fi-rr-sort:before {
    content: "\F3CB";
    }
        .fi-rr-soup:before {
    content: "\F3CC";
    }
        .fi-rr-spa:before {
    content: "\F3CD";
    }
        .fi-rr-space-shuttle:before {
    content: "\F3CE";
    }
        .fi-rr-spade:before {
    content: "\F3CF";
    }
        .fi-rr-sparkles:before {
    content: "\F3D0";
    }
        .fi-rr-speaker:before {
    content: "\F3D1";
    }
        .fi-rr-sphere:before {
    content: "\F3D2";
    }
        .fi-rr-spinner:before {
    content: "\F3D3";
    }
        .fi-rr-spoon:before {
    content: "\F3D4";
    }
        .fi-rr-square-root:before {
    content: "\F3D5";
    }
        .fi-rr-square:before {
    content: "\F3D6";
    }
        .fi-rr-star-octogram:before {
    content: "\F3D7";
    }
        .fi-rr-star:before {
    content: "\F3D8";
    }
        .fi-rr-starfighter:before {
    content: "\F3D9";
    }
        .fi-rr-stars:before {
    content: "\F3DA";
    }
        .fi-rr-stats:before {
    content: "\F3DB";
    }
        .fi-rr-steak:before {
    content: "\F3DC";
    }
        .fi-rr-steering-wheel:before {
    content: "\F3DD";
    }
        .fi-rr-stethoscope:before {
    content: "\F3DE";
    }
        .fi-rr-sticker:before {
    content: "\F3DF";
    }
        .fi-rr-stop:before {
    content: "\F3E0";
    }
        .fi-rr-stopwatch:before {
    content: "\F3E1";
    }
        .fi-rr-strawberry:before {
    content: "\F3E2";
    }
        .fi-rr-subtitles:before {
    content: "\F3E3";
    }
        .fi-rr-subway:before {
    content: "\F3E4";
    }
        .fi-rr-sun:before {
    content: "\F3E5";
    }
        .fi-rr-sunrise-alt:before {
    content: "\F3E6";
    }
        .fi-rr-sunrise:before {
    content: "\F3E7";
    }
        .fi-rr-sunset:before {
    content: "\F3E8";
    }
        .fi-rr-surfing:before {
    content: "\F3E9";
    }
        .fi-rr-surprise:before {
    content: "\F3EA";
    }
        .fi-rr-sushi:before {
    content: "\F3EB";
    }
        .fi-rr-swimmer:before {
    content: "\F3EC";
    }
        .fi-rr-sword:before {
    content: "\F3ED";
    }
        .fi-rr-syringe:before {
    content: "\F3EE";
    }
        .fi-rr-tablet:before {
    content: "\F3EF";
    }
        .fi-rr-tachometer-alt-average:before {
    content: "\F3F0";
    }
        .fi-rr-tachometer-alt-fastest:before {
    content: "\F3F1";
    }
        .fi-rr-tachometer-alt-slow:before {
    content: "\F3F2";
    }
        .fi-rr-tachometer-alt-slowest:before {
    content: "\F3F3";
    }
        .fi-rr-tachometer-average:before {
    content: "\F3F4";
    }
        .fi-rr-tachometer-fast:before {
    content: "\F3F5";
    }
        .fi-rr-tachometer-fastest:before {
    content: "\F3F6";
    }
        .fi-rr-tachometer-slow:before {
    content: "\F3F7";
    }
        .fi-rr-tachometer-slowest:before {
    content: "\F3F8";
    }
        .fi-rr-tachometer:before {
    content: "\F3F9";
    }
        .fi-rr-taco:before {
    content: "\F3FA";
    }
        .fi-rr-target:before {
    content: "\F3FB";
    }
        .fi-rr-taxi:before {
    content: "\F3FC";
    }
        .fi-rr-temperature-down:before {
    content: "\F3FD";
    }
        .fi-rr-temperature-frigid:before {
    content: "\F3FE";
    }
        .fi-rr-temperature-high:before {
    content: "\F3FF";
    }
        .fi-rr-temperature-hot:before {
    content: "\F400";
    }
        .fi-rr-temperature-low:before {
    content: "\F401";
    }
        .fi-rr-temperature-up:before {
    content: "\F402";
    }
        .fi-rr-tennis:before {
    content: "\F403";
    }
        .fi-rr-terrace:before {
    content: "\F404";
    }
        .fi-rr-test-tube:before {
    content: "\F405";
    }
        .fi-rr-test:before {
    content: "\F406";
    }
        .fi-rr-text-check:before {
    content: "\F407";
    }
        .fi-rr-text:before {
    content: "\F408";
    }
        .fi-rr-thermometer-half:before {
    content: "\F409";
    }
        .fi-rr-thumbs-down:before {
    content: "\F40A";
    }
        .fi-rr-thumbs-up:before {
    content: "\F40B";
    }
        .fi-rr-thumbtack:before {
    content: "\F40C";
    }
        .fi-rr-thunderstorm-moon:before {
    content: "\F40D";
    }
        .fi-rr-thunderstorm-sun:before {
    content: "\F40E";
    }
        .fi-rr-thunderstorm:before {
    content: "\F40F";
    }
        .fi-rr-ticket:before {
    content: "\F410";
    }
        .fi-rr-time-add:before {
    content: "\F411";
    }
        .fi-rr-time-check:before {
    content: "\F412";
    }
        .fi-rr-time-delete:before {
    content: "\F413";
    }
        .fi-rr-time-fast:before {
    content: "\F414";
    }
        .fi-rr-time-forward-sixty:before {
    content: "\F415";
    }
        .fi-rr-time-forward-ten:before {
    content: "\F416";
    }
        .fi-rr-time-forward:before {
    content: "\F417";
    }
        .fi-rr-time-half-past:before {
    content: "\F418";
    }
        .fi-rr-time-oclock:before {
    content: "\F419";
    }
        .fi-rr-time-past:before {
    content: "\F41A";
    }
        .fi-rr-time-quarter-to:before {
    content: "\F41B";
    }
        .fi-rr-time-quarter-past:before {
    content: "\F41C";
    }
        .fi-rr-time-twenty-four:before {
    content: "\F41D";
    }
        .fi-rr-tire-flat:before {
    content: "\F41E";
    }
        .fi-rr-tire-pressure-warning:before {
    content: "\F41F";
    }
        .fi-rr-tire-rugged:before {
    content: "\F420";
    }
        .fi-rr-tire:before {
    content: "\F421";
    }
        .fi-rr-tired:before {
    content: "\F422";
    }
        .fi-rr-tomato:before {
    content: "\F423";
    }
        .fi-rr-tool-crop:before {
    content: "\F424";
    }
        .fi-rr-tool-marquee:before {
    content: "\F425";
    }
        .fi-rr-tooth:before {
    content: "\F426";
    }
        .fi-rr-tornado:before {
    content: "\F427";
    }
        .fi-rr-tractor:before {
    content: "\F428";
    }
        .fi-rr-trailer:before {
    content: "\F429";
    }
        .fi-rr-train-side:before {
    content: "\F42A";
    }
        .fi-rr-train:before {
    content: "\F42B";
    }
        .fi-rr-tram:before {
    content: "\F42C";
    }
        .fi-rr-transform:before {
    content: "\F42D";
    }
        .fi-rr-trash:before {
    content: "\F42E";
    }
        .fi-rr-treatment:before {
    content: "\F42F";
    }
        .fi-rr-tree-christmas:before {
    content: "\F430";
    }
        .fi-rr-tree:before {
    content: "\F431";
    }
        .fi-rr-triangle:before {
    content: "\F432";
    }
        .fi-rr-trophy:before {
    content: "\F433";
    }
        .fi-rr-truck-container:before {
    content: "\F434";
    }
        .fi-rr-truck-couch:before {
    content: "\F435";
    }
        .fi-rr-truck-loading:before {
    content: "\F436";
    }
        .fi-rr-truck-monster:before {
    content: "\F437";
    }
        .fi-rr-truck-moving:before {
    content: "\F438";
    }
        .fi-rr-truck-pickup:before {
    content: "\F439";
    }
        .fi-rr-truck-plow:before {
    content: "\F43A";
    }
        .fi-rr-truck-ramp:before {
    content: "\F43B";
    }
        .fi-rr-truck-side:before {
    content: "\F43C";
    }
        .fi-rr-tty:before {
    content: "\F43D";
    }
        .fi-rr-turkey:before {
    content: "\F43E";
    }
        .fi-rr-umbrella:before {
    content: "\F43F";
    }
        .fi-rr-underline:before {
    content: "\F440";
    }
        .fi-rr-undo-alt:before {
    content: "\F441";
    }
        .fi-rr-undo:before {
    content: "\F442";
    }
        .fi-rr-unlock:before {
    content: "\F443";
    }
        .fi-rr-upload:before {
    content: "\F444";
    }
        .fi-rr-usb-pendrive:before {
    content: "\F445";
    }
        .fi-rr-user-add:before {
    content: "\F446";
    }
        .fi-rr-user-time:before {
    content: "\F447";
    }
        .fi-rr-user:before {
    content: "\F448";
    }
        .fi-rr-users-alt:before {
    content: "\F449";
    }
        .fi-rr-users:before {
    content: "\F44A";
    }
        .fi-rr-utensils:before {
    content: "\F44B";
    }
        .fi-rr-vector-alt:before {
    content: "\F44C";
    }
        .fi-rr-vector:before {
    content: "\F44D";
    }
        .fi-rr-venus-double:before {
    content: "\F44E";
    }
        .fi-rr-venus-mars:before {
    content: "\F44F";
    }
        .fi-rr-venus:before {
    content: "\F450";
    }
        .fi-rr-video-camera:before {
    content: "\F451";
    }
        .fi-rr-volcano:before {
    content: "\F452";
    }
        .fi-rr-volleyball:before {
    content: "\F453";
    }
        .fi-rr-volume:before {
    content: "\F454";
    }
        .fi-rr-wagon-covered:before {
    content: "\F455";
    }
        .fi-rr-water-bottle:before {
    content: "\F456";
    }
        .fi-rr-water-lower:before {
    content: "\F457";
    }
        .fi-rr-water-rise:before {
    content: "\F458";
    }
        .fi-rr-water:before {
    content: "\F459";
    }
        .fi-rr-watermelon:before {
    content: "\F45A";
    }
        .fi-rr-wheat:before {
    content: "\F45B";
    }
        .fi-rr-wheelchair:before {
    content: "\F45C";
    }
        .fi-rr-whistle:before {
    content: "\F45D";
    }
        .fi-rr-wifi-alt:before {
    content: "\F45E";
    }
        .fi-rr-wind-warning:before {
    content: "\F45F";
    }
        .fi-rr-wind:before {
    content: "\F460";
    }
        .fi-rr-windsock:before {
    content: "\F461";
    }
        .fi-rr-woman-head:before {
    content: "\F462";
    }
        .fi-rr-world:before {
    content: "\F463";
    }
        .fi-rr-yen:before {
    content: "\F464";
    }
        .fi-rr-zoom-in:before {
    content: "\F465";
    }
        .fi-rr-zoom-out:before {
    content: "\F466";
    }

@font-face {
font-family: "uicons-solid-rounded";
src: url(/static/media/uicons-solid-rounded.34d019d3.eot#iefix) format("embedded-opentype"),
url(/static/media/uicons-solid-rounded.c8f0f6e5.woff2) format("woff2"),
url(/static/media/uicons-solid-rounded.6147dd58.woff) format("woff");
}

    i[class^="fi-sr-"]:before, i[class*=" fi-sr-"]:before, span[class^="fi-sr-"]:before, span[class*="fi-sr-"]:before {
font-family: uicons-solid-rounded !important;
font-style: normal;
font-weight: normal !important;
font-feature-settings: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-sr-add-document:before {
    content: "\F101";
    }
        .fi-sr-add-folder:before {
    content: "\F102";
    }
        .fi-sr-add:before {
    content: "\F103";
    }
        .fi-sr-address-book:before {
    content: "\F104";
    }
        .fi-sr-alarm-clock:before {
    content: "\F105";
    }
        .fi-sr-align-center:before {
    content: "\F106";
    }
        .fi-sr-align-justify:before {
    content: "\F107";
    }
        .fi-sr-align-left:before {
    content: "\F108";
    }
        .fi-sr-align-right:before {
    content: "\F109";
    }
        .fi-sr-ambulance:before {
    content: "\F10A";
    }
        .fi-sr-angle-circle-down:before {
    content: "\F10B";
    }
        .fi-sr-angle-circle-left:before {
    content: "\F10C";
    }
        .fi-sr-angle-circle-right:before {
    content: "\F10D";
    }
        .fi-sr-angle-circle-up:before {
    content: "\F10E";
    }
        .fi-sr-angle-double-left:before {
    content: "\F10F";
    }
        .fi-sr-angle-double-right:before {
    content: "\F110";
    }
        .fi-sr-angle-double-small-down:before {
    content: "\F111";
    }
        .fi-sr-angle-double-small-left:before {
    content: "\F112";
    }
        .fi-sr-angle-double-small-right:before {
    content: "\F113";
    }
        .fi-sr-angle-double-small-up:before {
    content: "\F114";
    }
        .fi-sr-angle-down:before {
    content: "\F115";
    }
        .fi-sr-angle-left:before {
    content: "\F116";
    }
        .fi-sr-angle-right:before {
    content: "\F117";
    }
        .fi-sr-angle-small-down:before {
    content: "\F118";
    }
        .fi-sr-angle-small-left:before {
    content: "\F119";
    }
        .fi-sr-angle-small-right:before {
    content: "\F11A";
    }
        .fi-sr-angle-small-up:before {
    content: "\F11B";
    }
        .fi-sr-angle-square-down:before {
    content: "\F11C";
    }
        .fi-sr-angle-square-left:before {
    content: "\F11D";
    }
        .fi-sr-angle-square-right:before {
    content: "\F11E";
    }
        .fi-sr-angle-square-up:before {
    content: "\F11F";
    }
        .fi-sr-angle-up:before {
    content: "\F120";
    }
        .fi-sr-angry:before {
    content: "\F121";
    }
        .fi-sr-apple-whole:before {
    content: "\F122";
    }
        .fi-sr-apps-add:before {
    content: "\F123";
    }
        .fi-sr-apps-delete:before {
    content: "\F124";
    }
        .fi-sr-apps-sort:before {
    content: "\F125";
    }
        .fi-sr-apps:before {
    content: "\F126";
    }
        .fi-sr-archive:before {
    content: "\F127";
    }
        .fi-sr-arrow-alt-circle-down:before {
    content: "\F128";
    }
        .fi-sr-arrow-alt-circle-left:before {
    content: "\F129";
    }
        .fi-sr-arrow-alt-circle-right:before {
    content: "\F12A";
    }
        .fi-sr-arrow-alt-circle-up:before {
    content: "\F12B";
    }
        .fi-sr-arrow-alt-down:before {
    content: "\F12C";
    }
        .fi-sr-arrow-alt-from-bottom:before {
    content: "\F12D";
    }
        .fi-sr-arrow-alt-from-left:before {
    content: "\F12E";
    }
        .fi-sr-arrow-alt-from-right:before {
    content: "\F12F";
    }
        .fi-sr-arrow-alt-from-top:before {
    content: "\F130";
    }
        .fi-sr-arrow-alt-left:before {
    content: "\F131";
    }
        .fi-sr-arrow-alt-right:before {
    content: "\F132";
    }
        .fi-sr-arrow-alt-square-down:before {
    content: "\F133";
    }
        .fi-sr-arrow-alt-square-left:before {
    content: "\F134";
    }
        .fi-sr-arrow-alt-square-right:before {
    content: "\F135";
    }
        .fi-sr-arrow-alt-square-up:before {
    content: "\F136";
    }
        .fi-sr-arrow-alt-to-bottom:before {
    content: "\F137";
    }
        .fi-sr-arrow-alt-to-left:before {
    content: "\F138";
    }
        .fi-sr-arrow-alt-to-right:before {
    content: "\F139";
    }
        .fi-sr-arrow-alt-to-top:before {
    content: "\F13A";
    }
        .fi-sr-arrow-alt-up:before {
    content: "\F13B";
    }
        .fi-sr-arrow-circle-down:before {
    content: "\F13C";
    }
        .fi-sr-arrow-circle-left:before {
    content: "\F13D";
    }
        .fi-sr-arrow-circle-right:before {
    content: "\F13E";
    }
        .fi-sr-arrow-circle-up:before {
    content: "\F13F";
    }
        .fi-sr-arrow-down:before {
    content: "\F140";
    }
        .fi-sr-arrow-from-bottom:before {
    content: "\F141";
    }
        .fi-sr-arrow-from-left:before {
    content: "\F142";
    }
        .fi-sr-arrow-from-right:before {
    content: "\F143";
    }
        .fi-sr-arrow-from-top:before {
    content: "\F144";
    }
        .fi-sr-arrow-left:before {
    content: "\F145";
    }
        .fi-sr-arrow-right:before {
    content: "\F146";
    }
        .fi-sr-arrow-small-down:before {
    content: "\F147";
    }
        .fi-sr-arrow-small-left:before {
    content: "\F148";
    }
        .fi-sr-arrow-small-right:before {
    content: "\F149";
    }
        .fi-sr-arrow-small-up:before {
    content: "\F14A";
    }
        .fi-sr-arrow-square-down:before {
    content: "\F14B";
    }
        .fi-sr-arrow-square-left:before {
    content: "\F14C";
    }
        .fi-sr-arrow-square-right:before {
    content: "\F14D";
    }
        .fi-sr-arrow-square-up:before {
    content: "\F14E";
    }
        .fi-sr-arrow-to-bottom:before {
    content: "\F14F";
    }
        .fi-sr-arrow-to-left:before {
    content: "\F150";
    }
        .fi-sr-arrow-to-right:before {
    content: "\F151";
    }
        .fi-sr-arrow-to-top:before {
    content: "\F152";
    }
        .fi-sr-arrow-up:before {
    content: "\F153";
    }
        .fi-sr-arrows-alt-h:before {
    content: "\F154";
    }
        .fi-sr-arrows-alt-v:before {
    content: "\F155";
    }
        .fi-sr-arrows-alt:before {
    content: "\F156";
    }
        .fi-sr-arrows-h-copy:before {
    content: "\F157";
    }
        .fi-sr-arrows-h:before {
    content: "\F158";
    }
        .fi-sr-arrows:before {
    content: "\F159";
    }
        .fi-sr-assept-document:before {
    content: "\F15A";
    }
        .fi-sr-assistive-listening-systems:before {
    content: "\F15B";
    }
        .fi-sr-asterik:before {
    content: "\F15C";
    }
        .fi-sr-at:before {
    content: "\F15D";
    }
        .fi-sr-aubergine:before {
    content: "\F15E";
    }
        .fi-sr-avocado:before {
    content: "\F15F";
    }
        .fi-sr-baby-carriage:before {
    content: "\F160";
    }
        .fi-sr-backpack:before {
    content: "\F161";
    }
        .fi-sr-bacon:before {
    content: "\F162";
    }
        .fi-sr-badge:before {
    content: "\F163";
    }
        .fi-sr-balloons:before {
    content: "\F164";
    }
        .fi-sr-ban:before {
    content: "\F165";
    }
        .fi-sr-band-aid:before {
    content: "\F166";
    }
        .fi-sr-bank:before {
    content: "\F167";
    }
        .fi-sr-barber-shop:before {
    content: "\F168";
    }
        .fi-sr-baseball-alt:before {
    content: "\F169";
    }
        .fi-sr-baseball:before {
    content: "\F16A";
    }
        .fi-sr-basketball-hoop:before {
    content: "\F16B";
    }
        .fi-sr-basketball:before {
    content: "\F16C";
    }
        .fi-sr-bed:before {
    content: "\F16D";
    }
        .fi-sr-beer:before {
    content: "\F16E";
    }
        .fi-sr-bell-ring:before {
    content: "\F16F";
    }
        .fi-sr-bell-school:before {
    content: "\F170";
    }
        .fi-sr-bell:before {
    content: "\F171";
    }
        .fi-sr-bike:before {
    content: "\F172";
    }
        .fi-sr-biking-mountain:before {
    content: "\F173";
    }
        .fi-sr-biking:before {
    content: "\F174";
    }
        .fi-sr-billiard:before {
    content: "\F175";
    }
        .fi-sr-bold:before {
    content: "\F176";
    }
        .fi-sr-bolt:before {
    content: "\F177";
    }
        .fi-sr-book-alt:before {
    content: "\F178";
    }
        .fi-sr-book:before {
    content: "\F179";
    }
        .fi-sr-bookmark:before {
    content: "\F17A";
    }
        .fi-sr-bottle:before {
    content: "\F17B";
    }
        .fi-sr-bow-arrow:before {
    content: "\F17C";
    }
        .fi-sr-bowling-ball:before {
    content: "\F17D";
    }
        .fi-sr-bowling-pins:before {
    content: "\F17E";
    }
        .fi-sr-bowling:before {
    content: "\F17F";
    }
        .fi-sr-box-alt:before {
    content: "\F180";
    }
        .fi-sr-box:before {
    content: "\F181";
    }
        .fi-sr-boxing-glove:before {
    content: "\F182";
    }
        .fi-sr-braille:before {
    content: "\F183";
    }
        .fi-sr-bread-slice:before {
    content: "\F184";
    }
        .fi-sr-bread:before {
    content: "\F185";
    }
        .fi-sr-briefcase:before {
    content: "\F186";
    }
        .fi-sr-broccoli:before {
    content: "\F187";
    }
        .fi-sr-broom:before {
    content: "\F188";
    }
        .fi-sr-browser:before {
    content: "\F189";
    }
        .fi-sr-brush:before {
    content: "\F18A";
    }
        .fi-sr-bug:before {
    content: "\F18B";
    }
        .fi-sr-building:before {
    content: "\F18C";
    }
        .fi-sr-bulb:before {
    content: "\F18D";
    }
        .fi-sr-burrito:before {
    content: "\F18E";
    }
        .fi-sr-bus-alt:before {
    content: "\F18F";
    }
        .fi-sr-bus:before {
    content: "\F190";
    }
        .fi-sr-butterfly:before {
    content: "\F191";
    }
        .fi-sr-cake-birthday:before {
    content: "\F192";
    }
        .fi-sr-cake-wedding:before {
    content: "\F193";
    }
        .fi-sr-calculator:before {
    content: "\F194";
    }
        .fi-sr-calendar:before {
    content: "\F195";
    }
        .fi-sr-call-history:before {
    content: "\F196";
    }
        .fi-sr-call-incoming:before {
    content: "\F197";
    }
        .fi-sr-call-missed:before {
    content: "\F198";
    }
        .fi-sr-call-outgoing:before {
    content: "\F199";
    }
        .fi-sr-camera:before {
    content: "\F19A";
    }
        .fi-sr-camping:before {
    content: "\F19B";
    }
        .fi-sr-candy-alt:before {
    content: "\F19C";
    }
        .fi-sr-candy:before {
    content: "\F19D";
    }
        .fi-sr-canned-food:before {
    content: "\F19E";
    }
        .fi-sr-car-alt:before {
    content: "\F19F";
    }
        .fi-sr-car-battery:before {
    content: "\F1A0";
    }
        .fi-sr-car-building:before {
    content: "\F1A1";
    }
        .fi-sr-car-bump:before {
    content: "\F1A2";
    }
        .fi-sr-car-bus:before {
    content: "\F1A3";
    }
        .fi-sr-car-crash:before {
    content: "\F1A4";
    }
        .fi-sr-car-garage:before {
    content: "\F1A5";
    }
        .fi-sr-car-mechanic:before {
    content: "\F1A6";
    }
        .fi-sr-car-side:before {
    content: "\F1A7";
    }
        .fi-sr-car-tilt:before {
    content: "\F1A8";
    }
        .fi-sr-car-wash:before {
    content: "\F1A9";
    }
        .fi-sr-car:before {
    content: "\F1AA";
    }
        .fi-sr-caravan-alt:before {
    content: "\F1AB";
    }
        .fi-sr-caravan:before {
    content: "\F1AC";
    }
        .fi-sr-caret-circle-down:before {
    content: "\F1AD";
    }
        .fi-sr-caret-circle-right:before {
    content: "\F1AE";
    }
        .fi-sr-caret-circle-up:before {
    content: "\F1AF";
    }
        .fi-sr-caret-down:before {
    content: "\F1B0";
    }
        .fi-sr-caret-left:before {
    content: "\F1B1";
    }
        .fi-sr-caret-quare-up:before {
    content: "\F1B2";
    }
        .fi-sr-caret-right:before {
    content: "\F1B3";
    }
        .fi-sr-caret-square-down:before {
    content: "\F1B4";
    }
        .fi-sr-caret-square-left_1:before {
    content: "\F1B5";
    }
        .fi-sr-caret-square-left:before {
    content: "\F1B6";
    }
        .fi-sr-caret-square-right:before {
    content: "\F1B7";
    }
        .fi-sr-caret-up:before {
    content: "\F1B8";
    }
        .fi-sr-carrot:before {
    content: "\F1B9";
    }
        .fi-sr-cars:before {
    content: "\F1BA";
    }
        .fi-sr-charging-station:before {
    content: "\F1BB";
    }
        .fi-sr-chart-connected:before {
    content: "\F1BC";
    }
        .fi-sr-chart-histogram:before {
    content: "\F1BD";
    }
        .fi-sr-chart-network:before {
    content: "\F1BE";
    }
        .fi-sr-chart-pie-alt:before {
    content: "\F1BF";
    }
        .fi-sr-chart-pie:before {
    content: "\F1C0";
    }
        .fi-sr-chart-pyramid:before {
    content: "\F1C1";
    }
        .fi-sr-chart-set-theory:before {
    content: "\F1C2";
    }
        .fi-sr-chart-tree:before {
    content: "\F1C3";
    }
        .fi-sr-chat-arrow-down:before {
    content: "\F1C4";
    }
        .fi-sr-chat-arrow-grow:before {
    content: "\F1C5";
    }
        .fi-sr-check:before {
    content: "\F1C6";
    }
        .fi-sr-checkbox:before {
    content: "\F1C7";
    }
        .fi-sr-cheese:before {
    content: "\F1C8";
    }
        .fi-sr-cherry:before {
    content: "\F1C9";
    }
        .fi-sr-chess-piece:before {
    content: "\F1CA";
    }
        .fi-sr-chess-bishop:before {
    content: "\F1CB";
    }
        .fi-sr-chess-board:before {
    content: "\F1CC";
    }
        .fi-sr-chess-clock-alt:before {
    content: "\F1CD";
    }
        .fi-sr-chess-clock:before {
    content: "\F1CE";
    }
        .fi-sr-chess-king-alt:before {
    content: "\F1CF";
    }
        .fi-sr-chess-king:before {
    content: "\F1D0";
    }
        .fi-sr-chess-knight-alt:before {
    content: "\F1D1";
    }
        .fi-sr-chess-knight:before {
    content: "\F1D2";
    }
        .fi-sr-chess-pawn-alt:before {
    content: "\F1D3";
    }
        .fi-sr-chess-pawn:before {
    content: "\F1D4";
    }
        .fi-sr-chess-queen-alt:before {
    content: "\F1D5";
    }
        .fi-sr-chess-queen:before {
    content: "\F1D6";
    }
        .fi-sr-chess-rook-alt:before {
    content: "\F1D7";
    }
        .fi-sr-chess-rook:before {
    content: "\F1D8";
    }
        .fi-sr-chess:before {
    content: "\F1D9";
    }
        .fi-sr-chevron-double-down:before {
    content: "\F1DA";
    }
        .fi-sr-chevron-double-up:before {
    content: "\F1DB";
    }
        .fi-sr-child-head:before {
    content: "\F1DC";
    }
        .fi-sr-chocolate:before {
    content: "\F1DD";
    }
        .fi-sr-circle-small:before {
    content: "\F1DE";
    }
        .fi-sr-circle:before {
    content: "\F1DF";
    }
        .fi-sr-clip:before {
    content: "\F1E0";
    }
        .fi-sr-clock:before {
    content: "\F1E1";
    }
        .fi-sr-cloud-upload:before {
    content: "\F1E2";
    }
        .fi-sr-cloud-check:before {
    content: "\F1E3";
    }
        .fi-sr-cloud-disabled:before {
    content: "\F1E4";
    }
        .fi-sr-cloud-download-alt:before {
    content: "\F1E5";
    }
        .fi-sr-cloud-download:before {
    content: "\F1E6";
    }
        .fi-sr-cloud-drizzle:before {
    content: "\F1E7";
    }
        .fi-sr-cloud-hail-mixed:before {
    content: "\F1E8";
    }
        .fi-sr-cloud-hail:before {
    content: "\F1E9";
    }
        .fi-sr-cloud-moon-rain:before {
    content: "\F1EA";
    }
        .fi-sr-cloud-moon:before {
    content: "\F1EB";
    }
        .fi-sr-cloud-rain:before {
    content: "\F1EC";
    }
        .fi-sr-cloud-rainbow:before {
    content: "\F1ED";
    }
        .fi-sr-cloud-share:before {
    content: "\F1EE";
    }
        .fi-sr-cloud-showers-heavy:before {
    content: "\F1EF";
    }
        .fi-sr-cloud-showers:before {
    content: "\F1F0";
    }
        .fi-sr-cloud-sleet:before {
    content: "\F1F1";
    }
        .fi-sr-cloud-snow:before {
    content: "\F1F2";
    }
        .fi-sr-cloud-sun-rain:before {
    content: "\F1F3";
    }
        .fi-sr-cloud-sun:before {
    content: "\F1F4";
    }
        .fi-sr-cloud-upload-alt:before {
    content: "\F1F5";
    }
        .fi-sr-cloud:before {
    content: "\F1F6";
    }
        .fi-sr-clouds-moon:before {
    content: "\F1F7";
    }
        .fi-sr-clouds-sun:before {
    content: "\F1F8";
    }
        .fi-sr-clouds:before {
    content: "\F1F9";
    }
        .fi-sr-club:before {
    content: "\F1FA";
    }
        .fi-sr-cocktail-alt:before {
    content: "\F1FB";
    }
        .fi-sr-cocktail:before {
    content: "\F1FC";
    }
        .fi-sr-coffee-pot:before {
    content: "\F1FD";
    }
        .fi-sr-coffee:before {
    content: "\F1FE";
    }
        .fi-sr-comment-alt:before {
    content: "\F1FF";
    }
        .fi-sr-comment-check:before {
    content: "\F200";
    }
        .fi-sr-comment-heart:before {
    content: "\F201";
    }
        .fi-sr-comment-info:before {
    content: "\F202";
    }
        .fi-sr-comment-user:before {
    content: "\F203";
    }
        .fi-sr-comment:before {
    content: "\F204";
    }
        .fi-sr-comments:before {
    content: "\F205";
    }
        .fi-sr-compress-alt:before {
    content: "\F206";
    }
        .fi-sr-compress:before {
    content: "\F207";
    }
        .fi-sr-computer:before {
    content: "\F208";
    }
        .fi-sr-confetti:before {
    content: "\F209";
    }
        .fi-sr-cookie:before {
    content: "\F20A";
    }
        .fi-sr-copy-alt:before {
    content: "\F20B";
    }
        .fi-sr-copy:before {
    content: "\F20C";
    }
        .fi-sr-copyright:before {
    content: "\F20D";
    }
        .fi-sr-corn:before {
    content: "\F20E";
    }
        .fi-sr-cow:before {
    content: "\F20F";
    }
        .fi-sr-cream:before {
    content: "\F210";
    }
        .fi-sr-credit-card:before {
    content: "\F211";
    }
        .fi-sr-cricket:before {
    content: "\F212";
    }
        .fi-sr-croissant:before {
    content: "\F213";
    }
        .fi-sr-cross-circle:before {
    content: "\F214";
    }
        .fi-sr-cross-small:before {
    content: "\F215";
    }
        .fi-sr-cross:before {
    content: "\F216";
    }
        .fi-sr-crown:before {
    content: "\F217";
    }
        .fi-sr-cube:before {
    content: "\F218";
    }
        .fi-sr-cupcake:before {
    content: "\F219";
    }
        .fi-sr-curling:before {
    content: "\F21A";
    }
        .fi-sr-cursor-finger:before {
    content: "\F21B";
    }
        .fi-sr-cursor-plus:before {
    content: "\F21C";
    }
        .fi-sr-cursor-text-alt:before {
    content: "\F21D";
    }
        .fi-sr-cursor-text:before {
    content: "\F21E";
    }
        .fi-sr-cursor:before {
    content: "\F21F";
    }
        .fi-sr-dart:before {
    content: "\F220";
    }
        .fi-sr-dashboard:before {
    content: "\F221";
    }
        .fi-sr-data-transfer:before {
    content: "\F222";
    }
        .fi-sr-database:before {
    content: "\F223";
    }
        .fi-sr-delete-document:before {
    content: "\F224";
    }
        .fi-sr-delete-user:before {
    content: "\F225";
    }
        .fi-sr-delete:before {
    content: "\F226";
    }
        .fi-sr-dewpoint:before {
    content: "\F227";
    }
        .fi-sr-diamond:before {
    content: "\F228";
    }
        .fi-sr-dice-alt:before {
    content: "\F229";
    }
        .fi-sr-dice-d10:before {
    content: "\F22A";
    }
        .fi-sr-dice-d12:before {
    content: "\F22B";
    }
        .fi-sr-dice-d20:before {
    content: "\F22C";
    }
        .fi-sr-dice-d4:before {
    content: "\F22D";
    }
        .fi-sr-dice-d6:before {
    content: "\F22E";
    }
        .fi-sr-dice-d8:before {
    content: "\F22F";
    }
        .fi-sr-dice-four:before {
    content: "\F230";
    }
        .fi-sr-dice-one:before {
    content: "\F231";
    }
        .fi-sr-dice-six:before {
    content: "\F232";
    }
        .fi-sr-dice-three:before {
    content: "\F233";
    }
        .fi-sr-dice-two:before {
    content: "\F234";
    }
        .fi-sr-dice:before {
    content: "\F235";
    }
        .fi-sr-diploma:before {
    content: "\F236";
    }
        .fi-sr-disco-ball:before {
    content: "\F237";
    }
        .fi-sr-disk:before {
    content: "\F238";
    }
        .fi-sr-dizzy:before {
    content: "\F239";
    }
        .fi-sr-doctor:before {
    content: "\F23A";
    }
        .fi-sr-document-signed:before {
    content: "\F23B";
    }
        .fi-sr-document:before {
    content: "\F23C";
    }
        .fi-sr-dollar:before {
    content: "\F23D";
    }
        .fi-sr-download:before {
    content: "\F23E";
    }
        .fi-sr-dreidel:before {
    content: "\F23F";
    }
        .fi-sr-drink-alt:before {
    content: "\F240";
    }
        .fi-sr-drumstick:before {
    content: "\F241";
    }
        .fi-sr-duplicate:before {
    content: "\F242";
    }
        .fi-sr-e-learning:before {
    content: "\F243";
    }
        .fi-sr-earnings:before {
    content: "\F244";
    }
        .fi-sr-eclipse-alt:before {
    content: "\F245";
    }
        .fi-sr-eclipse:before {
    content: "\F246";
    }
        .fi-sr-edit-alt:before {
    content: "\F247";
    }
        .fi-sr-edit:before {
    content: "\F248";
    }
        .fi-sr-egg-fried:before {
    content: "\F249";
    }
        .fi-sr-egg:before {
    content: "\F24A";
    }
        .fi-sr-engine-warning:before {
    content: "\F24B";
    }
        .fi-sr-envelope-ban:before {
    content: "\F24C";
    }
        .fi-sr-envelope-download:before {
    content: "\F24D";
    }
        .fi-sr-envelope-marker:before {
    content: "\F24E";
    }
        .fi-sr-envelope-open:before {
    content: "\F24F";
    }
        .fi-sr-envelope-plus:before {
    content: "\F250";
    }
        .fi-sr-envelope:before {
    content: "\F251";
    }
        .fi-sr-euro:before {
    content: "\F252";
    }
        .fi-sr-exchange-alt:before {
    content: "\F253";
    }
        .fi-sr-exchange:before {
    content: "\F254";
    }
        .fi-sr-exclamation:before {
    content: "\F255";
    }
        .fi-sr-expand-arrows-alt:before {
    content: "\F256";
    }
        .fi-sr-expand-arrows:before {
    content: "\F257";
    }
        .fi-sr-expand:before {
    content: "\F258";
    }
        .fi-sr-eye-crossed:before {
    content: "\F259";
    }
        .fi-sr-eye-dropper:before {
    content: "\F25A";
    }
        .fi-sr-eye:before {
    content: "\F25B";
    }
        .fi-sr-feather:before {
    content: "\F25C";
    }
        .fi-sr-ferris-wheel:before {
    content: "\F25D";
    }
        .fi-sr-field-hockey:before {
    content: "\F25E";
    }
        .fi-sr-fighter-jet:before {
    content: "\F25F";
    }
        .fi-sr-file-ai:before {
    content: "\F260";
    }
        .fi-sr-file-eps:before {
    content: "\F261";
    }
        .fi-sr-file-psd:before {
    content: "\F262";
    }
        .fi-sr-file:before {
    content: "\F263";
    }
        .fi-sr-fill:before {
    content: "\F264";
    }
        .fi-sr-film:before {
    content: "\F265";
    }
        .fi-sr-filter:before {
    content: "\F266";
    }
        .fi-sr-fingerprint:before {
    content: "\F267";
    }
        .fi-sr-fish:before {
    content: "\F268";
    }
        .fi-sr-flag:before {
    content: "\F269";
    }
        .fi-sr-flame:before {
    content: "\F26A";
    }
        .fi-sr-flip-horizontal:before {
    content: "\F26B";
    }
        .fi-sr-flower-bouquet:before {
    content: "\F26C";
    }
        .fi-sr-flower-tulip:before {
    content: "\F26D";
    }
        .fi-sr-flower:before {
    content: "\F26E";
    }
        .fi-sr-flushed:before {
    content: "\F26F";
    }
        .fi-sr-fog:before {
    content: "\F270";
    }
        .fi-sr-folder:before {
    content: "\F271";
    }
        .fi-sr-following:before {
    content: "\F272";
    }
        .fi-sr-football:before {
    content: "\F273";
    }
        .fi-sr-fork:before {
    content: "\F274";
    }
        .fi-sr-form:before {
    content: "\F275";
    }
        .fi-sr-forward:before {
    content: "\F276";
    }
        .fi-sr-fox:before {
    content: "\F277";
    }
        .fi-sr-french-fries:before {
    content: "\F278";
    }
        .fi-sr-frown:before {
    content: "\F279";
    }
        .fi-sr-ftp:before {
    content: "\F27A";
    }
        .fi-sr-gallery:before {
    content: "\F27B";
    }
        .fi-sr-game-board-alt:before {
    content: "\F27C";
    }
        .fi-sr-gamepad:before {
    content: "\F27D";
    }
        .fi-sr-garage-car:before {
    content: "\F27E";
    }
        .fi-sr-garage-open:before {
    content: "\F27F";
    }
        .fi-sr-garage:before {
    content: "\F280";
    }
        .fi-sr-garlic:before {
    content: "\F281";
    }
        .fi-sr-gas-pump-alt:before {
    content: "\F282";
    }
        .fi-sr-gas-pump-slash:before {
    content: "\F283";
    }
        .fi-sr-gas-pump:before {
    content: "\F284";
    }
        .fi-sr-gem:before {
    content: "\F285";
    }
        .fi-sr-gif:before {
    content: "\F286";
    }
        .fi-sr-gift:before {
    content: "\F287";
    }
        .fi-sr-gingerbread-man:before {
    content: "\F288";
    }
        .fi-sr-glass-cheers:before {
    content: "\F289";
    }
        .fi-sr-glass:before {
    content: "\F28A";
    }
        .fi-sr-glasses:before {
    content: "\F28B";
    }
        .fi-sr-globe-alt:before {
    content: "\F28C";
    }
        .fi-sr-globe:before {
    content: "\F28D";
    }
        .fi-sr-golf-ball:before {
    content: "\F28E";
    }
        .fi-sr-golf-club:before {
    content: "\F28F";
    }
        .fi-sr-golf:before {
    content: "\F290";
    }
        .fi-sr-graduation-cap:before {
    content: "\F291";
    }
        .fi-sr-grape:before {
    content: "\F292";
    }
        .fi-sr-graphic-tablet:before {
    content: "\F293";
    }
        .fi-sr-grid-alt:before {
    content: "\F294";
    }
        .fi-sr-grid:before {
    content: "\F295";
    }
        .fi-sr-grill:before {
    content: "\F296";
    }
        .fi-sr-grimace:before {
    content: "\F297";
    }
        .fi-sr-grin-alt:before {
    content: "\F298";
    }
        .fi-sr-grin-beam-sweat:before {
    content: "\F299";
    }
        .fi-sr-grin-beam:before {
    content: "\F29A";
    }
        .fi-sr-grin-hearts:before {
    content: "\F29B";
    }
        .fi-sr-grin-squint-tears:before {
    content: "\F29C";
    }
        .fi-sr-grin-squint:before {
    content: "\F29D";
    }
        .fi-sr-grin-stars:before {
    content: "\F29E";
    }
        .fi-sr-grin-tears:before {
    content: "\F29F";
    }
        .fi-sr-grin-tongue-squint:before {
    content: "\F2A0";
    }
        .fi-sr-grin-tongue-wink:before {
    content: "\F2A1";
    }
        .fi-sr-grin-tongue:before {
    content: "\F2A2";
    }
        .fi-sr-grin-wink:before {
    content: "\F2A3";
    }
        .fi-sr-grin:before {
    content: "\F2A4";
    }
        .fi-sr-guitar:before {
    content: "\F2A5";
    }
        .fi-sr-gym:before {
    content: "\F2A6";
    }
        .fi-sr-hamburger-soda:before {
    content: "\F2A7";
    }
        .fi-sr-hamburger:before {
    content: "\F2A8";
    }
        .fi-sr-hand-holding-heart:before {
    content: "\F2A9";
    }
        .fi-sr-hastag:before {
    content: "\F2AA";
    }
        .fi-sr-hat-birthday:before {
    content: "\F2AB";
    }
        .fi-sr-hat-chef:before {
    content: "\F2AC";
    }
        .fi-sr-head-side-thinking:before {
    content: "\F2AD";
    }
        .fi-sr-headphones:before {
    content: "\F2AE";
    }
        .fi-sr-headset:before {
    content: "\F2AF";
    }
        .fi-sr-heart-arrow:before {
    content: "\F2B0";
    }
        .fi-sr-heart:before {
    content: "\F2B1";
    }
        .fi-sr-heat:before {
    content: "\F2B2";
    }
        .fi-sr-helicopter-side:before {
    content: "\F2B3";
    }
        .fi-sr-hiking:before {
    content: "\F2B4";
    }
        .fi-sr-hockey-mask:before {
    content: "\F2B5";
    }
        .fi-sr-hockey-puck:before {
    content: "\F2B6";
    }
        .fi-sr-hockey-sticks:before {
    content: "\F2B7";
    }
        .fi-sr-home-location-alt:before {
    content: "\F2B8";
    }
        .fi-sr-home-location:before {
    content: "\F2B9";
    }
        .fi-sr-home:before {
    content: "\F2BA";
    }
        .fi-sr-hotdog:before {
    content: "\F2BB";
    }
        .fi-sr-hourglass-end:before {
    content: "\F2BC";
    }
        .fi-sr-hourglass:before {
    content: "\F2BD";
    }
        .fi-sr-house-flood:before {
    content: "\F2BE";
    }
        .fi-sr-humidity:before {
    content: "\F2BF";
    }
        .fi-sr-hurricane:before {
    content: "\F2C0";
    }
        .fi-sr-ice-cream:before {
    content: "\F2C1";
    }
        .fi-sr-ice-skate:before {
    content: "\F2C2";
    }
        .fi-sr-id-badge:before {
    content: "\F2C3";
    }
        .fi-sr-inbox-in:before {
    content: "\F2C4";
    }
        .fi-sr-inbox-out:before {
    content: "\F2C5";
    }
        .fi-sr-inbox:before {
    content: "\F2C6";
    }
        .fi-sr-incognito:before {
    content: "\F2C7";
    }
        .fi-sr-indent:before {
    content: "\F2C8";
    }
        .fi-sr-infinity:before {
    content: "\F2C9";
    }
        .fi-sr-info:before {
    content: "\F2CA";
    }
        .fi-sr-interactive:before {
    content: "\F2CB";
    }
        .fi-sr-interlining:before {
    content: "\F2CC";
    }
        .fi-sr-interrogation:before {
    content: "\F2CD";
    }
        .fi-sr-italic:before {
    content: "\F2CE";
    }
        .fi-sr-jam:before {
    content: "\F2CF";
    }
        .fi-sr-jpg:before {
    content: "\F2D0";
    }
        .fi-sr-key:before {
    content: "\F2D1";
    }
        .fi-sr-keyboard:before {
    content: "\F2D2";
    }
        .fi-sr-kiss-beam:before {
    content: "\F2D3";
    }
        .fi-sr-kiss-wink-heart:before {
    content: "\F2D4";
    }
        .fi-sr-kiss:before {
    content: "\F2D5";
    }
        .fi-sr-kite:before {
    content: "\F2D6";
    }
        .fi-sr-knife:before {
    content: "\F2D7";
    }
        .fi-sr-label:before {
    content: "\F2D8";
    }
        .fi-sr-laptop:before {
    content: "\F2D9";
    }
        .fi-sr-lasso:before {
    content: "\F2DA";
    }
        .fi-sr-laugh-beam:before {
    content: "\F2DB";
    }
        .fi-sr-laugh-squint:before {
    content: "\F2DC";
    }
        .fi-sr-laugh-wink:before {
    content: "\F2DD";
    }
        .fi-sr-laugh:before {
    content: "\F2DE";
    }
        .fi-sr-layers:before {
    content: "\F2DF";
    }
        .fi-sr-layout-fluid:before {
    content: "\F2E0";
    }
        .fi-sr-leaf:before {
    content: "\F2E1";
    }
        .fi-sr-lemon:before {
    content: "\F2E2";
    }
        .fi-sr-letter-case:before {
    content: "\F2E3";
    }
        .fi-sr-lettuce:before {
    content: "\F2E4";
    }
        .fi-sr-level-down-alt:before {
    content: "\F2E5";
    }
        .fi-sr-level-down:before {
    content: "\F2E6";
    }
        .fi-sr-level-up-alt:before {
    content: "\F2E7";
    }
        .fi-sr-level-up:before {
    content: "\F2E8";
    }
        .fi-sr-life-ring:before {
    content: "\F2E9";
    }
        .fi-sr-line-width:before {
    content: "\F2EA";
    }
        .fi-sr-link:before {
    content: "\F2EB";
    }
        .fi-sr-lipstick:before {
    content: "\F2EC";
    }
        .fi-sr-list-check:before {
    content: "\F2ED";
    }
        .fi-sr-list:before {
    content: "\F2EE";
    }
        .fi-sr-loading:before {
    content: "\F2EF";
    }
        .fi-sr-location-alt:before {
    content: "\F2F0";
    }
        .fi-sr-lock-alt:before {
    content: "\F2F1";
    }
        .fi-sr-lock:before {
    content: "\F2F2";
    }
        .fi-sr-luchador:before {
    content: "\F2F3";
    }
        .fi-sr-luggage-rolling:before {
    content: "\F2F4";
    }
        .fi-sr-magic-wand:before {
    content: "\F2F5";
    }
        .fi-sr-makeup-brush:before {
    content: "\F2F6";
    }
        .fi-sr-man-head:before {
    content: "\F2F7";
    }
        .fi-sr-map-marker-cross:before {
    content: "\F2F8";
    }
        .fi-sr-map-marker-home:before {
    content: "\F2F9";
    }
        .fi-sr-map-marker-minus:before {
    content: "\F2FA";
    }
        .fi-sr-map-marker-plus:before {
    content: "\F2FB";
    }
        .fi-sr-map-marker:before {
    content: "\F2FC";
    }
        .fi-sr-map:before {
    content: "\F2FD";
    }
        .fi-sr-marker-time:before {
    content: "\F2FE";
    }
        .fi-sr-marker:before {
    content: "\F2FF";
    }
        .fi-sr-mars-double:before {
    content: "\F300";
    }
        .fi-sr-mars:before {
    content: "\F301";
    }
        .fi-sr-mask-carnival:before {
    content: "\F302";
    }
        .fi-sr-medicine:before {
    content: "\F303";
    }
        .fi-sr-megaphone:before {
    content: "\F304";
    }
        .fi-sr-meh-blank:before {
    content: "\F305";
    }
        .fi-sr-meh-rolling-eyes:before {
    content: "\F306";
    }
        .fi-sr-meh:before {
    content: "\F307";
    }
        .fi-sr-melon:before {
    content: "\F308";
    }
        .fi-sr-menu-burger:before {
    content: "\F309";
    }
        .fi-sr-menu-dots-vertical:before {
    content: "\F30A";
    }
        .fi-sr-menu-dots:before {
    content: "\F30B";
    }
        .fi-sr-meteor:before {
    content: "\F30C";
    }
        .fi-sr-microphone-alt:before {
    content: "\F30D";
    }
        .fi-sr-microphone:before {
    content: "\F30E";
    }
        .fi-sr-minus-small:before {
    content: "\F30F";
    }
        .fi-sr-minus:before {
    content: "\F310";
    }
        .fi-sr-mobile:before {
    content: "\F311";
    }
        .fi-sr-mode-landscape:before {
    content: "\F312";
    }
        .fi-sr-mode-portrait:before {
    content: "\F313";
    }
        .fi-sr-money:before {
    content: "\F314";
    }
        .fi-sr-moon-stars:before {
    content: "\F315";
    }
        .fi-sr-moon:before {
    content: "\F316";
    }
        .fi-sr-motorcycle:before {
    content: "\F317";
    }
        .fi-sr-mountains:before {
    content: "\F318";
    }
        .fi-sr-mouse:before {
    content: "\F319";
    }
        .fi-sr-mug-alt:before {
    content: "\F31A";
    }
        .fi-sr-mug-hot-alt:before {
    content: "\F31B";
    }
        .fi-sr-mug-hot:before {
    content: "\F31C";
    }
        .fi-sr-mug-tea:before {
    content: "\F31D";
    }
        .fi-sr-mug:before {
    content: "\F31E";
    }
        .fi-sr-mushroom:before {
    content: "\F31F";
    }
        .fi-sr-music-file:before {
    content: "\F320";
    }
        .fi-sr-music-alt:before {
    content: "\F321";
    }
        .fi-sr-music:before {
    content: "\F322";
    }
        .fi-sr-navigation:before {
    content: "\F323";
    }
        .fi-sr-network-cloud:before {
    content: "\F324";
    }
        .fi-sr-network:before {
    content: "\F325";
    }
        .fi-sr-noodles:before {
    content: "\F326";
    }
        .fi-sr-notebook:before {
    content: "\F327";
    }
        .fi-sr-oil-can:before {
    content: "\F328";
    }
        .fi-sr-oil-temp:before {
    content: "\F329";
    }
        .fi-sr-olive-oil:before {
    content: "\F32A";
    }
        .fi-sr-olives:before {
    content: "\F32B";
    }
        .fi-sr-onion:before {
    content: "\F32C";
    }
        .fi-sr-opacity:before {
    content: "\F32D";
    }
        .fi-sr-package:before {
    content: "\F32E";
    }
        .fi-sr-paint-brush:before {
    content: "\F32F";
    }
        .fi-sr-palette:before {
    content: "\F330";
    }
        .fi-sr-pan:before {
    content: "\F331";
    }
        .fi-sr-paper-plane:before {
    content: "\F332";
    }
        .fi-sr-password:before {
    content: "\F333";
    }
        .fi-sr-pause:before {
    content: "\F334";
    }
        .fi-sr-paw:before {
    content: "\F335";
    }
        .fi-sr-peach:before {
    content: "\F336";
    }
        .fi-sr-pencil:before {
    content: "\F337";
    }
        .fi-sr-pennant:before {
    content: "\F338";
    }
        .fi-sr-pepper-hot:before {
    content: "\F339";
    }
        .fi-sr-pepper:before {
    content: "\F33A";
    }
        .fi-sr-pharmacy:before {
    content: "\F33B";
    }
        .fi-sr-phone-call:before {
    content: "\F33C";
    }
        .fi-sr-phone-cross:before {
    content: "\F33D";
    }
        .fi-sr-phone-pause:before {
    content: "\F33E";
    }
        .fi-sr-phone-slash:before {
    content: "\F33F";
    }
        .fi-sr-physics:before {
    content: "\F340";
    }
        .fi-sr-picnic:before {
    content: "\F341";
    }
        .fi-sr-picture:before {
    content: "\F342";
    }
        .fi-sr-pie:before {
    content: "\F343";
    }
        .fi-sr-pineapple:before {
    content: "\F344";
    }
        .fi-sr-ping-pong:before {
    content: "\F345";
    }
        .fi-sr-pizza-slice:before {
    content: "\F346";
    }
        .fi-sr-plane-alt:before {
    content: "\F347";
    }
        .fi-sr-plane:before {
    content: "\F348";
    }
        .fi-sr-plate:before {
    content: "\F349";
    }
        .fi-sr-play-alt:before {
    content: "\F34A";
    }
        .fi-sr-play:before {
    content: "\F34B";
    }
        .fi-sr-playing-cards:before {
    content: "\F34C";
    }
        .fi-sr-plus-small:before {
    content: "\F34D";
    }
        .fi-sr-plus:before {
    content: "\F34E";
    }
        .fi-sr-poker-chip:before {
    content: "\F34F";
    }
        .fi-sr-poo:before {
    content: "\F350";
    }
        .fi-sr-popcorn:before {
    content: "\F351";
    }
        .fi-sr-portrait:before {
    content: "\F352";
    }
        .fi-sr-pot:before {
    content: "\F353";
    }
        .fi-sr-pound:before {
    content: "\F354";
    }
        .fi-sr-power:before {
    content: "\F355";
    }
        .fi-sr-presentation:before {
    content: "\F356";
    }
        .fi-sr-print:before {
    content: "\F357";
    }
        .fi-sr-protractor:before {
    content: "\F358";
    }
        .fi-sr-pulse:before {
    content: "\F359";
    }
        .fi-sr-pumpkin:before {
    content: "\F35A";
    }
        .fi-sr-puzzle-piece:before {
    content: "\F35B";
    }
        .fi-sr-pyramid:before {
    content: "\F35C";
    }
        .fi-sr-quote-right:before {
    content: "\F35D";
    }
        .fi-sr-radish:before {
    content: "\F35E";
    }
        .fi-sr-rainbow:before {
    content: "\F35F";
    }
        .fi-sr-raindrops:before {
    content: "\F360";
    }
        .fi-sr-raquet:before {
    content: "\F361";
    }
        .fi-sr-rec:before {
    content: "\F362";
    }
        .fi-sr-receipt:before {
    content: "\F363";
    }
        .fi-sr-record-vinyl:before {
    content: "\F364";
    }
        .fi-sr-rectabgle-vertical:before {
    content: "\F365";
    }
        .fi-sr-rectangle-horizontal:before {
    content: "\F366";
    }
        .fi-sr-rectangle-panoramic:before {
    content: "\F367";
    }
        .fi-sr-recycle:before {
    content: "\F368";
    }
        .fi-sr-redo-alt:before {
    content: "\F369";
    }
        .fi-sr-redo:before {
    content: "\F36A";
    }
        .fi-sr-reflect:before {
    content: "\F36B";
    }
        .fi-sr-refresh:before {
    content: "\F36C";
    }
        .fi-sr-remove-user:before {
    content: "\F36D";
    }
        .fi-sr-reply-all:before {
    content: "\F36E";
    }
        .fi-sr-resize:before {
    content: "\F36F";
    }
        .fi-sr-resources:before {
    content: "\F370";
    }
        .fi-sr-restaurant:before {
    content: "\F371";
    }
        .fi-sr-rewind:before {
    content: "\F372";
    }
        .fi-sr-rhombus:before {
    content: "\F373";
    }
        .fi-sr-rings-wedding:before {
    content: "\F374";
    }
        .fi-sr-road:before {
    content: "\F375";
    }
        .fi-sr-rocket-lunch:before {
    content: "\F376";
    }
        .fi-sr-rocket:before {
    content: "\F377";
    }
        .fi-sr-room-service:before {
    content: "\F378";
    }
        .fi-sr-rotate-right:before {
    content: "\F379";
    }
        .fi-sr-rugby-helmet:before {
    content: "\F37A";
    }
        .fi-sr-rugby:before {
    content: "\F37B";
    }
        .fi-sr-running:before {
    content: "\F37C";
    }
        .fi-sr-rv:before {
    content: "\F37D";
    }
        .fi-sr-sad-cry:before {
    content: "\F37E";
    }
        .fi-sr-sad-tear:before {
    content: "\F37F";
    }
        .fi-sr-sad:before {
    content: "\F380";
    }
        .fi-sr-salad:before {
    content: "\F381";
    }
        .fi-sr-salt-pepper:before {
    content: "\F382";
    }
        .fi-sr-sandwich:before {
    content: "\F383";
    }
        .fi-sr-sauce:before {
    content: "\F384";
    }
        .fi-sr-sausage:before {
    content: "\F385";
    }
        .fi-sr-scale:before {
    content: "\F386";
    }
        .fi-sr-school-bus:before {
    content: "\F387";
    }
        .fi-sr-school:before {
    content: "\F388";
    }
        .fi-sr-scissors:before {
    content: "\F389";
    }
        .fi-sr-screen:before {
    content: "\F38A";
    }
        .fi-sr-search-alt:before {
    content: "\F38B";
    }
        .fi-sr-search-heart:before {
    content: "\F38C";
    }
        .fi-sr-search:before {
    content: "\F38D";
    }
        .fi-sr-settings-sliders:before {
    content: "\F38E";
    }
        .fi-sr-settings:before {
    content: "\F38F";
    }
        .fi-sr-share:before {
    content: "\F390";
    }
        .fi-sr-shield-check:before {
    content: "\F391";
    }
        .fi-sr-shield-exclamation:before {
    content: "\F392";
    }
        .fi-sr-shield-interrogation:before {
    content: "\F393";
    }
        .fi-sr-shield-plus:before {
    content: "\F394";
    }
        .fi-sr-shield:before {
    content: "\F395";
    }
        .fi-sr-ship-side:before {
    content: "\F396";
    }
        .fi-sr-ship:before {
    content: "\F397";
    }
        .fi-sr-shop:before {
    content: "\F398";
    }
        .fi-sr-shopping-bag-add:before {
    content: "\F399";
    }
        .fi-sr-shopping-bag:before {
    content: "\F39A";
    }
        .fi-sr-shopping-cart-add:before {
    content: "\F39B";
    }
        .fi-sr-shopping-cart-check:before {
    content: "\F39C";
    }
        .fi-sr-shopping-cart:before {
    content: "\F39D";
    }
        .fi-sr-shrimp:before {
    content: "\F39E";
    }
        .fi-sr-shuffle:before {
    content: "\F39F";
    }
        .fi-sr-shuttle-van:before {
    content: "\F3A0";
    }
        .fi-sr-shuttlecock:before {
    content: "\F3A1";
    }
        .fi-sr-sign-in-alt:before {
    content: "\F3A2";
    }
        .fi-sr-sign-in:before {
    content: "\F3A3";
    }
        .fi-sr-sign-out-alt:before {
    content: "\F3A4";
    }
        .fi-sr-sign-out:before {
    content: "\F3A5";
    }
        .fi-sr-signal-alt-1:before {
    content: "\F3A6";
    }
        .fi-sr-signal-alt-2:before {
    content: "\F3A7";
    }
        .fi-sr-signal-alt:before {
    content: "\F3A8";
    }
        .fi-sr-skateboard:before {
    content: "\F3A9";
    }
        .fi-sr-skating:before {
    content: "\F3AA";
    }
        .fi-sr-skewer:before {
    content: "\F3AB";
    }
        .fi-sr-ski-jump:before {
    content: "\F3AC";
    }
        .fi-sr-ski-lift:before {
    content: "\F3AD";
    }
        .fi-sr-skiing-nordic:before {
    content: "\F3AE";
    }
        .fi-sr-skiing:before {
    content: "\F3AF";
    }
        .fi-sr-sledding:before {
    content: "\F3B0";
    }
        .fi-sr-sleigh:before {
    content: "\F3B1";
    }
        .fi-sr-smartphone:before {
    content: "\F3B2";
    }
        .fi-sr-smile-beam:before {
    content: "\F3B3";
    }
        .fi-sr-smile-wink:before {
    content: "\F3B4";
    }
        .fi-sr-smile:before {
    content: "\F3B5";
    }
        .fi-sr-smog:before {
    content: "\F3B6";
    }
        .fi-sr-smoke:before {
    content: "\F3B7";
    }
        .fi-sr-snow-blowing:before {
    content: "\F3B8";
    }
        .fi-sr-snowboarding:before {
    content: "\F3B9";
    }
        .fi-sr-snowflake:before {
    content: "\F3BA";
    }
        .fi-sr-snowflakes:before {
    content: "\F3BB";
    }
        .fi-sr-snowmobile:before {
    content: "\F3BC";
    }
        .fi-sr-snowplow:before {
    content: "\F3BD";
    }
        .fi-sr-soap:before {
    content: "\F3BE";
    }
        .fi-sr-sort-alpha-down-alt:before {
    content: "\F3BF";
    }
        .fi-sr-sort-alpha-down:before {
    content: "\F3C0";
    }
        .fi-sr-sort-alpha-up-alt:before {
    content: "\F3C1";
    }
        .fi-sr-sort-alpha-up:before {
    content: "\F3C2";
    }
        .fi-sr-sort-alt:before {
    content: "\F3C3";
    }
        .fi-sr-sort-amount-down-alt:before {
    content: "\F3C4";
    }
        .fi-sr-sort-amount-down:before {
    content: "\F3C5";
    }
        .fi-sr-sort-amount-up-alt:before {
    content: "\F3C6";
    }
        .fi-sr-sort-amount-up:before {
    content: "\F3C7";
    }
        .fi-sr-sort-down:before {
    content: "\F3C8";
    }
        .fi-sr-sort-numeric-down-alt:before {
    content: "\F3C9";
    }
        .fi-sr-sort-numeric-down:before {
    content: "\F3CA";
    }
        .fi-sr-sort:before {
    content: "\F3CB";
    }
        .fi-sr-soup:before {
    content: "\F3CC";
    }
        .fi-sr-spa:before {
    content: "\F3CD";
    }
        .fi-sr-space-shuttle:before {
    content: "\F3CE";
    }
        .fi-sr-spade:before {
    content: "\F3CF";
    }
        .fi-sr-sparkles:before {
    content: "\F3D0";
    }
        .fi-sr-speaker:before {
    content: "\F3D1";
    }
        .fi-sr-sphere:before {
    content: "\F3D2";
    }
        .fi-sr-spinner:before {
    content: "\F3D3";
    }
        .fi-sr-spoon:before {
    content: "\F3D4";
    }
        .fi-sr-square-root:before {
    content: "\F3D5";
    }
        .fi-sr-square:before {
    content: "\F3D6";
    }
        .fi-sr-star-octogram:before {
    content: "\F3D7";
    }
        .fi-sr-star:before {
    content: "\F3D8";
    }
        .fi-sr-starfighter:before {
    content: "\F3D9";
    }
        .fi-sr-stars:before {
    content: "\F3DA";
    }
        .fi-sr-stats:before {
    content: "\F3DB";
    }
        .fi-sr-steak:before {
    content: "\F3DC";
    }
        .fi-sr-steering-wheel:before {
    content: "\F3DD";
    }
        .fi-sr-stethoscope:before {
    content: "\F3DE";
    }
        .fi-sr-sticker:before {
    content: "\F3DF";
    }
        .fi-sr-stop:before {
    content: "\F3E0";
    }
        .fi-sr-stopwatch:before {
    content: "\F3E1";
    }
        .fi-sr-strawberry:before {
    content: "\F3E2";
    }
        .fi-sr-subtitles:before {
    content: "\F3E3";
    }
        .fi-sr-subway:before {
    content: "\F3E4";
    }
        .fi-sr-sun:before {
    content: "\F3E5";
    }
        .fi-sr-sunrise-alt:before {
    content: "\F3E6";
    }
        .fi-sr-sunrise:before {
    content: "\F3E7";
    }
        .fi-sr-sunset:before {
    content: "\F3E8";
    }
        .fi-sr-surfing:before {
    content: "\F3E9";
    }
        .fi-sr-surprise:before {
    content: "\F3EA";
    }
        .fi-sr-sushi:before {
    content: "\F3EB";
    }
        .fi-sr-swimmer:before {
    content: "\F3EC";
    }
        .fi-sr-sword:before {
    content: "\F3ED";
    }
        .fi-sr-syringe:before {
    content: "\F3EE";
    }
        .fi-sr-tablet:before {
    content: "\F3EF";
    }
        .fi-sr-tachometer-alt-average:before {
    content: "\F3F0";
    }
        .fi-sr-tachometer-alt-fastest:before {
    content: "\F3F1";
    }
        .fi-sr-tachometer-alt-slow:before {
    content: "\F3F2";
    }
        .fi-sr-tachometer-alt-slowest:before {
    content: "\F3F3";
    }
        .fi-sr-tachometer-average:before {
    content: "\F3F4";
    }
        .fi-sr-tachometer-fast:before {
    content: "\F3F5";
    }
        .fi-sr-tachometer-fastest:before {
    content: "\F3F6";
    }
        .fi-sr-tachometer-slow:before {
    content: "\F3F7";
    }
        .fi-sr-tachometer-slowest:before {
    content: "\F3F8";
    }
        .fi-sr-tachometer:before {
    content: "\F3F9";
    }
        .fi-sr-taco:before {
    content: "\F3FA";
    }
        .fi-sr-target:before {
    content: "\F3FB";
    }
        .fi-sr-taxi:before {
    content: "\F3FC";
    }
        .fi-sr-temperature-down:before {
    content: "\F3FD";
    }
        .fi-sr-temperature-frigid:before {
    content: "\F3FE";
    }
        .fi-sr-temperature-high:before {
    content: "\F3FF";
    }
        .fi-sr-temperature-hot:before {
    content: "\F400";
    }
        .fi-sr-temperature-low:before {
    content: "\F401";
    }
        .fi-sr-temperature-up:before {
    content: "\F402";
    }
        .fi-sr-tennis:before {
    content: "\F403";
    }
        .fi-sr-terrace:before {
    content: "\F404";
    }
        .fi-sr-test-tube:before {
    content: "\F405";
    }
        .fi-sr-test:before {
    content: "\F406";
    }
        .fi-sr-text-check:before {
    content: "\F407";
    }
        .fi-sr-text:before {
    content: "\F408";
    }
        .fi-sr-thermometer-half:before {
    content: "\F409";
    }
        .fi-sr-thumbs-down:before {
    content: "\F40A";
    }
        .fi-sr-thumbs-up:before {
    content: "\F40B";
    }
        .fi-sr-thumbtack:before {
    content: "\F40C";
    }
        .fi-sr-thunderstorm-moon:before {
    content: "\F40D";
    }
        .fi-sr-thunderstorm-sun:before {
    content: "\F40E";
    }
        .fi-sr-thunderstorm:before {
    content: "\F40F";
    }
        .fi-sr-ticket:before {
    content: "\F410";
    }
        .fi-sr-time-add:before {
    content: "\F411";
    }
        .fi-sr-time-check:before {
    content: "\F412";
    }
        .fi-sr-time-delete:before {
    content: "\F413";
    }
        .fi-sr-time-fast:before {
    content: "\F414";
    }
        .fi-sr-time-forward-sixty:before {
    content: "\F415";
    }
        .fi-sr-time-forward-ten:before {
    content: "\F416";
    }
        .fi-sr-time-forward:before {
    content: "\F417";
    }
        .fi-sr-time-half-past:before {
    content: "\F418";
    }
        .fi-sr-time-oclock:before {
    content: "\F419";
    }
        .fi-sr-time-past:before {
    content: "\F41A";
    }
        .fi-sr-time-quarter-to:before {
    content: "\F41B";
    }
        .fi-sr-time-quarter-past:before {
    content: "\F41C";
    }
        .fi-sr-time-twenty-four:before {
    content: "\F41D";
    }
        .fi-sr-tire-flat:before {
    content: "\F41E";
    }
        .fi-sr-tire-pressure-warning:before {
    content: "\F41F";
    }
        .fi-sr-tire-rugged:before {
    content: "\F420";
    }
        .fi-sr-tire:before {
    content: "\F421";
    }
        .fi-sr-tired:before {
    content: "\F422";
    }
        .fi-sr-tomato:before {
    content: "\F423";
    }
        .fi-sr-tool-crop:before {
    content: "\F424";
    }
        .fi-sr-tool-marquee:before {
    content: "\F425";
    }
        .fi-sr-tooth:before {
    content: "\F426";
    }
        .fi-sr-tornado:before {
    content: "\F427";
    }
        .fi-sr-tractor:before {
    content: "\F428";
    }
        .fi-sr-trailer:before {
    content: "\F429";
    }
        .fi-sr-train-side:before {
    content: "\F42A";
    }
        .fi-sr-train:before {
    content: "\F42B";
    }
        .fi-sr-tram:before {
    content: "\F42C";
    }
        .fi-sr-transform:before {
    content: "\F42D";
    }
        .fi-sr-trash:before {
    content: "\F42E";
    }
        .fi-sr-treatment:before {
    content: "\F42F";
    }
        .fi-sr-tree-christmas:before {
    content: "\F430";
    }
        .fi-sr-tree:before {
    content: "\F431";
    }
        .fi-sr-triangle:before {
    content: "\F432";
    }
        .fi-sr-trophy:before {
    content: "\F433";
    }
        .fi-sr-truck-container:before {
    content: "\F434";
    }
        .fi-sr-truck-couch:before {
    content: "\F435";
    }
        .fi-sr-truck-loading:before {
    content: "\F436";
    }
        .fi-sr-truck-monster:before {
    content: "\F437";
    }
        .fi-sr-truck-moving:before {
    content: "\F438";
    }
        .fi-sr-truck-pickup:before {
    content: "\F439";
    }
        .fi-sr-truck-plow:before {
    content: "\F43A";
    }
        .fi-sr-truck-ramp:before {
    content: "\F43B";
    }
        .fi-sr-truck-side:before {
    content: "\F43C";
    }
        .fi-sr-tty:before {
    content: "\F43D";
    }
        .fi-sr-turkey:before {
    content: "\F43E";
    }
        .fi-sr-umbrella:before {
    content: "\F43F";
    }
        .fi-sr-underline:before {
    content: "\F440";
    }
        .fi-sr-undo-alt:before {
    content: "\F441";
    }
        .fi-sr-undo:before {
    content: "\F442";
    }
        .fi-sr-unlock:before {
    content: "\F443";
    }
        .fi-sr-upload:before {
    content: "\F444";
    }
        .fi-sr-usb-pendrive:before {
    content: "\F445";
    }
        .fi-sr-user-add:before {
    content: "\F446";
    }
        .fi-sr-user-time:before {
    content: "\F447";
    }
        .fi-sr-user:before {
    content: "\F448";
    }
        .fi-sr-users-alt:before {
    content: "\F449";
    }
        .fi-sr-users:before {
    content: "\F44A";
    }
        .fi-sr-utensils:before {
    content: "\F44B";
    }
        .fi-sr-vector-alt:before {
    content: "\F44C";
    }
        .fi-sr-vector:before {
    content: "\F44D";
    }
        .fi-sr-venus-double:before {
    content: "\F44E";
    }
        .fi-sr-venus-mars:before {
    content: "\F44F";
    }
        .fi-sr-venus:before {
    content: "\F450";
    }
        .fi-sr-video-camera:before {
    content: "\F451";
    }
        .fi-sr-volcano:before {
    content: "\F452";
    }
        .fi-sr-volleyball:before {
    content: "\F453";
    }
        .fi-sr-volume:before {
    content: "\F454";
    }
        .fi-sr-wagon-covered:before {
    content: "\F455";
    }
        .fi-sr-water-bottle:before {
    content: "\F456";
    }
        .fi-sr-water-lower:before {
    content: "\F457";
    }
        .fi-sr-water-rise:before {
    content: "\F458";
    }
        .fi-sr-water:before {
    content: "\F459";
    }
        .fi-sr-watermelon:before {
    content: "\F45A";
    }
        .fi-sr-wheat:before {
    content: "\F45B";
    }
        .fi-sr-wheelchair:before {
    content: "\F45C";
    }
        .fi-sr-whistle:before {
    content: "\F45D";
    }
        .fi-sr-wifi-alt:before {
    content: "\F45E";
    }
        .fi-sr-wind-warning:before {
    content: "\F45F";
    }
        .fi-sr-wind:before {
    content: "\F460";
    }
        .fi-sr-windsock:before {
    content: "\F461";
    }
        .fi-sr-woman-head:before {
    content: "\F462";
    }
        .fi-sr-world:before {
    content: "\F463";
    }
        .fi-sr-yen:before {
    content: "\F464";
    }
        .fi-sr-zoom-in:before {
    content: "\F465";
    }
        .fi-sr-zoom-out:before {
    content: "\F466";
    }

@font-face {
font-family: "uicons-brands";
src: url(/static/media/uicons-brands.3d2ca724.eot#iefix) format("embedded-opentype"),
url(/static/media/uicons-brands.6c72a83c.woff2) format("woff2"),
url(/static/media/uicons-brands.49e37369.woff) format("woff");
}

    i[class^="fi-brands-"]:before, i[class*=" fi-brands-"]:before, span[class^="fi-brands-"]:before, span[class*="fi-brands-"]:before {
font-family: uicons-brands !important;
font-style: normal;
font-weight: normal !important;
font-feature-settings: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-brands-3m:before {
    content: "\F101";
    }
        .fi-brands-500px:before {
    content: "\F102";
    }
        .fi-brands-abbot-laboratories:before {
    content: "\F103";
    }
        .fi-brands-accusoft:before {
    content: "\F104";
    }
        .fi-brands-acrobat:before {
    content: "\F105";
    }
        .fi-brands-adobe:before {
    content: "\F106";
    }
        .fi-brands-aecom:before {
    content: "\F107";
    }
        .fi-brands-aero:before {
    content: "\F108";
    }
        .fi-brands-after-effects:before {
    content: "\F109";
    }
        .fi-brands-airbnb:before {
    content: "\F10A";
    }
        .fi-brands-algolia:before {
    content: "\F10B";
    }
        .fi-brands-amd:before {
    content: "\F10C";
    }
        .fi-brands-american-express:before {
    content: "\F10D";
    }
        .fi-brands-android:before {
    content: "\F10E";
    }
        .fi-brands-animate:before {
    content: "\F10F";
    }
        .fi-brands-app-store-ios:before {
    content: "\F110";
    }
        .fi-brands-apple-pay:before {
    content: "\F111";
    }
        .fi-brands-apple:before {
    content: "\F112";
    }
        .fi-brands-artstation:before {
    content: "\F113";
    }
        .fi-brands-astrazeneca:before {
    content: "\F114";
    }
        .fi-brands-asus:before {
    content: "\F115";
    }
        .fi-brands-atandt:before {
    content: "\F116";
    }
        .fi-brands-atlassian:before {
    content: "\F117";
    }
        .fi-brands-atom:before {
    content: "\F118";
    }
        .fi-brands-audition:before {
    content: "\F119";
    }
        .fi-brands-behance:before {
    content: "\F11A";
    }
        .fi-brands-bitcoin:before {
    content: "\F11B";
    }
        .fi-brands-blackberry:before {
    content: "\F11C";
    }
        .fi-brands-blogger:before {
    content: "\F11D";
    }
        .fi-brands-bluetooth:before {
    content: "\F11E";
    }
        .fi-brands-bootstrap:before {
    content: "\F11F";
    }
        .fi-brands-bridgestone:before {
    content: "\F120";
    }
        .fi-brands-burger-king:before {
    content: "\F121";
    }
        .fi-brands-c:before {
    content: "\F122";
    }
        .fi-brands-capture:before {
    content: "\F123";
    }
        .fi-brands-cc-apple-pay:before {
    content: "\F124";
    }
        .fi-brands-cc-diners-club:before {
    content: "\F125";
    }
        .fi-brands-cc-visa:before {
    content: "\F126";
    }
        .fi-brands-cc-amazon-pay:before {
    content: "\F127";
    }
        .fi-brands-centos:before {
    content: "\F128";
    }
        .fi-brands-character:before {
    content: "\F129";
    }
        .fi-brands-chromecast:before {
    content: "\F12A";
    }
        .fi-brands-cloudflare:before {
    content: "\F12B";
    }
        .fi-brands-confluence:before {
    content: "\F12C";
    }
        .fi-brands-creative-commons-by:before {
    content: "\F12D";
    }
        .fi-brands-creative-commons-nc-eu:before {
    content: "\F12E";
    }
        .fi-brands-creative-commons-nc-jp:before {
    content: "\F12F";
    }
        .fi-brands-creative-commons-nc:before {
    content: "\F130";
    }
        .fi-brands-creative-commons-nd:before {
    content: "\F131";
    }
        .fi-brands-creative-commons-pd-alt:before {
    content: "\F132";
    }
        .fi-brands-creative-commons-pd:before {
    content: "\F133";
    }
        .fi-brands-creative-commons-remix:before {
    content: "\F134";
    }
        .fi-brands-creative-commons-sa:before {
    content: "\F135";
    }
        .fi-brands-creative-commons-sampling-plus:before {
    content: "\F136";
    }
        .fi-brands-creative-commons-sampling:before {
    content: "\F137";
    }
        .fi-brands-creative-commons-share:before {
    content: "\F138";
    }
        .fi-brands-creative-commons-zero:before {
    content: "\F139";
    }
        .fi-brands-creative-commons:before {
    content: "\F13A";
    }
        .fi-brands-css3-alt:before {
    content: "\F13B";
    }
        .fi-brands-css3:before {
    content: "\F13C";
    }
        .fi-brands-dailymotion:before {
    content: "\F13D";
    }
        .fi-brands-deezer:before {
    content: "\F13E";
    }
        .fi-brands-delphi:before {
    content: "\F13F";
    }
        .fi-brands-dev:before {
    content: "\F140";
    }
        .fi-brands-devianart:before {
    content: "\F141";
    }
        .fi-brands-digg:before {
    content: "\F142";
    }
        .fi-brands-dimension:before {
    content: "\F143";
    }
        .fi-brands-discord:before {
    content: "\F144";
    }
        .fi-brands-docker:before {
    content: "\F145";
    }
        .fi-brands-dribbble:before {
    content: "\F146";
    }
        .fi-brands-dropbox:before {
    content: "\F147";
    }
        .fi-brands-drupal:before {
    content: "\F148";
    }
        .fi-brands-ebay:before {
    content: "\F149";
    }
        .fi-brands-elementor:before {
    content: "\F14A";
    }
        .fi-brands-ethereum:before {
    content: "\F14B";
    }
        .fi-brands-etsy:before {
    content: "\F14C";
    }
        .fi-brands-evernote:before {
    content: "\F14D";
    }
        .fi-brands-facebook-messenger:before {
    content: "\F14E";
    }
        .fi-brands-facebook:before {
    content: "\F14F";
    }
        .fi-brands-fedex:before {
    content: "\F150";
    }
        .fi-brands-figma:before {
    content: "\F151";
    }
        .fi-brands-firefox-browser:before {
    content: "\F152";
    }
        .fi-brands-firefox:before {
    content: "\F153";
    }
        .fi-brands-flickr:before {
    content: "\F154";
    }
        .fi-brands-flipboard:before {
    content: "\F155";
    }
        .fi-brands-fonts:before {
    content: "\F156";
    }
        .fi-brands-foursquare:before {
    content: "\F157";
    }
        .fi-brands-fresco:before {
    content: "\F158";
    }
        .fi-brands-github:before {
    content: "\F159";
    }
        .fi-brands-gitlab:before {
    content: "\F15A";
    }
        .fi-brands-goodreads:before {
    content: "\F15B";
    }
        .fi-brands-google:before {
    content: "\F15C";
    }
        .fi-brands-haskell:before {
    content: "\F15D";
    }
        .fi-brands-hbo:before {
    content: "\F15E";
    }
        .fi-brands-hotjar:before {
    content: "\F15F";
    }
        .fi-brands-html5:before {
    content: "\F160";
    }
        .fi-brands-huawei:before {
    content: "\F161";
    }
        .fi-brands-hubspot:before {
    content: "\F162";
    }
        .fi-brands-ibm:before {
    content: "\F163";
    }
        .fi-brands-illustrator:before {
    content: "\F164";
    }
        .fi-brands-imdb:before {
    content: "\F165";
    }
        .fi-brands-incopy:before {
    content: "\F166";
    }
        .fi-brands-indesign:before {
    content: "\F167";
    }
        .fi-brands-instagram:before {
    content: "\F168";
    }
        .fi-brands-intel:before {
    content: "\F169";
    }
        .fi-brands-invision:before {
    content: "\F16A";
    }
        .fi-brands-itunes:before {
    content: "\F16B";
    }
        .fi-brands-janseen:before {
    content: "\F16C";
    }
        .fi-brands-java:before {
    content: "\F16D";
    }
        .fi-brands-jcb:before {
    content: "\F16E";
    }
        .fi-brands-jira:before {
    content: "\F16F";
    }
        .fi-brands-johnson-and-johnson:before {
    content: "\F170";
    }
        .fi-brands-joomla:before {
    content: "\F171";
    }
        .fi-brands-js:before {
    content: "\F172";
    }
        .fi-brands-kickstarter:before {
    content: "\F173";
    }
        .fi-brands-line:before {
    content: "\F174";
    }
        .fi-brands-linkedin:before {
    content: "\F175";
    }
        .fi-brands-lisp:before {
    content: "\F176";
    }
        .fi-brands-mailchimp:before {
    content: "\F177";
    }
        .fi-brands-marriott-international:before {
    content: "\F178";
    }
        .fi-brands-mcdonalds:before {
    content: "\F179";
    }
        .fi-brands-media-encoder:before {
    content: "\F17A";
    }
        .fi-brands-medium:before {
    content: "\F17B";
    }
        .fi-brands-meta:before {
    content: "\F17C";
    }
        .fi-brands-microsoft-explorer:before {
    content: "\F17D";
    }
        .fi-brands-microsoft:before {
    content: "\F17E";
    }
        .fi-brands-mysql:before {
    content: "\F17F";
    }
        .fi-brands-napster:before {
    content: "\F180";
    }
        .fi-brands-nestle:before {
    content: "\F181";
    }
        .fi-brands-netflix:before {
    content: "\F182";
    }
        .fi-brands-node-js:before {
    content: "\F183";
    }
        .fi-brands-nvidia:before {
    content: "\F184";
    }
        .fi-brands-oracle:before {
    content: "\F185";
    }
        .fi-brands-patreon:before {
    content: "\F186";
    }
        .fi-brands-paypal:before {
    content: "\F187";
    }
        .fi-brands-pfizer:before {
    content: "\F188";
    }
        .fi-brands-photoshop-camera:before {
    content: "\F189";
    }
        .fi-brands-photoshop-express:before {
    content: "\F18A";
    }
        .fi-brands-photoshop-lightroom-classic:before {
    content: "\F18B";
    }
        .fi-brands-photoshop-lightroom:before {
    content: "\F18C";
    }
        .fi-brands-photoshop:before {
    content: "\F18D";
    }
        .fi-brands-php:before {
    content: "\F18E";
    }
        .fi-brands-pinterest:before {
    content: "\F18F";
    }
        .fi-brands-postgre:before {
    content: "\F190";
    }
        .fi-brands-premiere-rush:before {
    content: "\F191";
    }
        .fi-brands-premiere:before {
    content: "\F192";
    }
        .fi-brands-product-hunt:before {
    content: "\F193";
    }
        .fi-brands-python:before {
    content: "\F194";
    }
        .fi-brands-raspberry-pi:before {
    content: "\F195";
    }
        .fi-brands-reddit:before {
    content: "\F196";
    }
        .fi-brands-samsung:before {
    content: "\F197";
    }
        .fi-brands-sap:before {
    content: "\F198";
    }
        .fi-brands-sass:before {
    content: "\F199";
    }
        .fi-brands-shopify:before {
    content: "\F19A";
    }
        .fi-brands-siemens:before {
    content: "\F19B";
    }
        .fi-brands-sketch:before {
    content: "\F19C";
    }
        .fi-brands-skype:before {
    content: "\F19D";
    }
        .fi-brands-slack:before {
    content: "\F19E";
    }
        .fi-brands-snapchat:before {
    content: "\F19F";
    }
        .fi-brands-sony:before {
    content: "\F1A0";
    }
        .fi-brands-soundcloud:before {
    content: "\F1A1";
    }
        .fi-brands-spark:before {
    content: "\F1A2";
    }
        .fi-brands-spotify:before {
    content: "\F1A3";
    }
        .fi-brands-starbucks:before {
    content: "\F1A4";
    }
        .fi-brands-stock:before {
    content: "\F1A5";
    }
        .fi-brands-stripe:before {
    content: "\F1A6";
    }
        .fi-brands-substance-3d-designer:before {
    content: "\F1A7";
    }
        .fi-brands-substance-3d-painter:before {
    content: "\F1A8";
    }
        .fi-brands-substance-3d-sampler:before {
    content: "\F1A9";
    }
        .fi-brands-substance-3d-stager:before {
    content: "\F1AA";
    }
        .fi-brands-swift:before {
    content: "\F1AB";
    }
        .fi-brands-t-mobile:before {
    content: "\F1AC";
    }
        .fi-brands-telegram:before {
    content: "\F1AD";
    }
        .fi-brands-tencent:before {
    content: "\F1AE";
    }
        .fi-brands-the-home-depot:before {
    content: "\F1AF";
    }
        .fi-brands-tik-tok:before {
    content: "\F1B0";
    }
        .fi-brands-trello:before {
    content: "\F1B1";
    }
        .fi-brands-tripadvisor:before {
    content: "\F1B2";
    }
        .fi-brands-tumblr:before {
    content: "\F1B3";
    }
        .fi-brands-twitch:before {
    content: "\F1B4";
    }
        .fi-brands-twitter:before {
    content: "\F1B5";
    }
        .fi-brands-typescript:before {
    content: "\F1B6";
    }
        .fi-brands-uber:before {
    content: "\F1B7";
    }
        .fi-brands-ubuntu:before {
    content: "\F1B8";
    }
        .fi-brands-unilever:before {
    content: "\F1B9";
    }
        .fi-brands-unity:before {
    content: "\F1BA";
    }
        .fi-brands-unsplash:before {
    content: "\F1BB";
    }
        .fi-brands-ups:before {
    content: "\F1BC";
    }
        .fi-brands-usaa:before {
    content: "\F1BD";
    }
        .fi-brands-verizon:before {
    content: "\F1BE";
    }
        .fi-brands-vimeo:before {
    content: "\F1BF";
    }
        .fi-brands-visa:before {
    content: "\F1C0";
    }
        .fi-brands-visual-basic:before {
    content: "\F1C1";
    }
        .fi-brands-vk:before {
    content: "\F1C2";
    }
        .fi-brands-walmart:before {
    content: "\F1C3";
    }
        .fi-brands-whatsapp:before {
    content: "\F1C4";
    }
        .fi-brands-wikipedia:before {
    content: "\F1C5";
    }
        .fi-brands-windows:before {
    content: "\F1C6";
    }
        .fi-brands-wix:before {
    content: "\F1C7";
    }
        .fi-brands-wordpress:before {
    content: "\F1C8";
    }
        .fi-brands-xd:before {
    content: "\F1C9";
    }
        .fi-brands-xing:before {
    content: "\F1CA";
    }
        .fi-brands-yahoo:before {
    content: "\F1CB";
    }
        .fi-brands-yandex:before {
    content: "\F1CC";
    }
        .fi-brands-yelp:before {
    content: "\F1CD";
    }
        .fi-brands-youtube:before {
    content: "\F1CE";
    }
        .fi-brands-zoom:before {
    content: "\F1CF";
    }

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014   \A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map */
.App {
  text-align: center;
}

.html {
  height: 100%;
}

.nav_icon {
  color: #F5F5F5;
  font-size: 28px !important;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100%;
  vertical-align: middle;
}
.nav_button {
  background-color: #02735E;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 0px;
  /* padding-left: 12px;
  padding-right: 30px; */
  padding-top: 5px;
  font-family: "Segoe UI Light";
  text-align: left;
  min-height: 64px;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap:break-word;
  flex-wrap: nowrap;
}
.nav_button_open {
  justify-content: left;
  padding-left: 10px;
  padding-right: 0px;
  width: 125%;
}
.nav_button:active {
  background-color: #02735E;
}

.nav_button_text {
  display: inline-block;
  color: #F5F5F5;
  /* padding-left: 10px; */
  vertical-align: middle;
  font-size: 30px;
  word-wrap: inherit;
}
.nav_button:hover {
  background-color: #03A678;
  /* box-shadow: 0px 5px 5px #014040ab, 0px -5px 5px #014040ab; */
  border-radius: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.l-navbar {
  position: absolute;
  top: 0;
  color: black;
  left: -50%;
  background-color: #02735E;
  transition: all .3s ease-in-out;
  box-shadow: none;
  width: 20rem;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  padding: .5rem 0 .5rem 1.5rem
}

.nav_logo {
  margin-bottom: 2rem
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: .3s
}

.nav_link:hover {
  color: var(--white-color)
}

.nav_icon {
  font-size: 1.25rem
}

.show {
  left: 0
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem)
}

.active {
  color: var(--white-color)
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color)
}

.height-100 {
  height: 100vh
}

@media only screen and (min-width: 800px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
    height: 100%;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
  }

  .header_img {
    width: 40px;
    height: 40px
  }

  .padded {
    padding-left: 500px;
  }

  .header_img img {
    width: 45px
  }

  #nav-parent {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    overflow-x: hidden;
  }

  .l-navbar {
    /* left: 0%; */
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    height: 100vh;
    /* z-index: 99999; */
    align-self: flex-start;
    overflow-x: hidden;
  }

  .show {
    width: calc(var(--nav-width) + 156px)
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 188px)
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}


.unshown {
  max-width: 80px;
  box-shadow: 3px 4px 6px 3px rgba(0, 0, 0, 0.3);
}

.float{
	position:fixed;
	width:90px;
  /* z-index: 999; */
	height:90px;
	bottom:10px;
	right:10px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.548);
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
}

.float:hover {
  text-decoration: none;
}

.my-float{
  position: relative;
  top: 21%;
  left: 0%;
}

.contact-float{
  position:fixed;
  max-width:90%;
  bottom:110px;
  right:5%;
	background-color:#02735E;
	color: white;
	border-radius:15px;
	text-align:center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.548);
  padding: 15px 10px;
  z-index: 10001;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0,0, 0.0);
}
.contact-icon {
  font-style: normal;
  font-size: 22px;
  color: inherit;
}
.contact-badge {
  height: 33px;
  background-color: white;
  border-radius: 15px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 13px;
  font-family: "roboto";
  font-weight: 400;
  vertical-align: middle;
  color:#731702;
  margin-right: 8px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  border-style: none;
}
.contact-badge:hover {
  background-color: #731702;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  color: white;
}

#footer {
  min-height: 80px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#App {
  min-height: 100vh;
}
#main {
  min-height: calc(100vh - 100px);

}

.top-menu{
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 5px 5px #014040ab, 0px -5px 5px #014040ab;
	width:100%;
  padding: .3rem;
  padding-right: 1rem;
  /* z-index: 999; */
	background-color:#02735E;
	color:#FFF;
  border-color: white;
  border-width: 0px;
  border-style: solid;
	text-align:center;
  vertical-align: middle;
  text-decoration: none;
  transition: .3s;
}

.top-menu-content {
  display: flex;
  width: 100%;
  align-items: center;
}
.top-menu-text {
  color: white;
  height: 100%;
  padding: 1rem 1rem;
  vertical-align:middle;
  font-size: 1.4rem;
  font-family: "roboto";
  font-weight: 300;
  margin: auto 0;
  display: inline-block;
  
}
.top-menu-text::before {
  display: inline-block;
  vertical-align: middle;
}
.menu-icon-float{
  position: relative;
  top: 5%;
  left: 0%;
  cursor: pointer;
}
.top-menu-float{
  border-color: white;
  border-width: 1px;
  border-style: none;
	position:fixed;
  
  right: -150%;
  top: 0%;
  bottom: 0%;
	background-color: rgba(1, 64, 64, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
	color: white;
	border-radius:0px;
	text-align:center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.548);
  padding: 15px 10px;
  z-index: 150000;
  transition: .5s;
}

.menu-visible {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}

.top-menu-icon {
  font-style: normal;
  font-size: 22px;
  color: inherit;
  vertical-align: middle;
}
.top-menu-badge {
  height: 40px;
  line-height: 40px;
  background-color: white;
  border-radius: 20px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 18px;
  font-family: "roboto";
  font-weight: 400;
  vertical-align: middle;
  color: #731702;
  width: 80%;
  margin-right: 8px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  cursor: pointer;
}

.top-menu-badge:hover {
  background-color: #731702;
  color: white;
}
.Menu-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(115, 115, 115, 0.3);
}
.menu-li {
  text-decoration: none;
  list-style-type: none;
}

@media only screen and (min-width: 800px) {
  .top-menu {
    display: none;
  }
  .contact-float{
    position:fixed;
    max-width:350px;
    bottom:110px;
    right:20px;
  }
}

.menu-close-badge {
  height: 40px;
  line-height: 40px;
  background-color: #F27405;
  border-radius: 20px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 18px;
  font-family: "roboto";
  font-weight: 400;
  vertical-align: middle;
  color: white;
  width: 50%;
  margin-right: 8px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  cursor: pointer;
}

.left-menu-close {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 40px !important;
  background: transparent;
}
.left-menu-close:hover {
  background-color: #03A678;
}
.breadcrumb {
  color: white;
  text-decoration: none;
  background-color: #014040;
  font-family: "roboto";
  font-size: 1.1rem;
  vertical-align: middle;
}
.breadcrumb:hover {
  text-decoration: none;
}
.breadcrumb-span {
  background-color: #03A678;
  padding: .2rem .3rem;
  border-radius: 5px;
}
.Link>.breadcrumb {
  color: white;
  text-decoration: none;
}
.outer-circle {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    margin: 20px;
    display: inline-block;
    background-color: #02735E;
    text-align: center;
    vertical-align: middle;
}

.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}
.quick-action {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 31px;
    border-width: 3px;
    border-color: #F27405;
    border-style: solid;
    text-align: left;
}

.quick-action:hover {
    background-color: #03A678;
    cursor: pointer;
}

.cert-div {
    background-color: lightgray;
    background: lightgray !important;
    margin: 1rem;
    border-radius: 5px;
}

.home-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
}
@media only screen and (max-width: 800px) {
    .home-title {
        font-size: 40px;
    }
    .outer-circle {
        display: none;
    }
    .home-title {
        display: none;
    }
    .home-hr {
        display: none;
    }
  }
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.link-icon {
    color:#F27405;
    font-family: 'roboto';
    font-weight: 100;
    font-size: 32px;
    font-style: normal;
    vertical-align: middle;
}
.link-icon::before {
    vertical-align: middle;
}
.major-caption {
    color: white;
    font-family: 'roboto';
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
}
.major-details {
    color: white;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 18px;
    font-style: normal;
}
.skill-badge {
    height: 22px;
    background-color: #731702;
    padding-right: 8px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;
    vertical-align: middle;
    color: white;
    margin-right: 8px;
}
.experience_header {
    font-family: 'roboto';
    font-size: 18px;
    font-weight: 400;
    color: white;
    margin-bottom: 5px;
    padding-bottom: 5px;
}
.experience-location {
    font-family: 'roboto';
    font-size: 14px;
    font-weight: 300;
    color: white;
    padding-bottom: 10px;
}
.experience-blurb {
    font-family: 'roboto';
    font-size: 16px;
    font-weight: 400;
    color: white;
    font-style: normal;
}
.experience-date {
        font-family: 'roboto';
        font-size: 18px;
        font-weight: 400;
        color: #7dffda;
}

.tag-badge {
    height: 22px;
    background-color: #03A678;
    padding-right: 8px;
    border-radius: 12px;
    margin-right: 5px;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;
    vertical-align: middle;
    color: white;
    margin-right: 8px;
}

.back-icon {
    cursor: pointer;
    color: white;
    font-size:xx-large;
    display: inline-block;
    padding-right: 3%;
}

.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}
.proj-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
}
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
@media only screen and (max-width: 800px) {
    .proj-title {
        font-size: 40px;
    }
  }
.project-card {
    margin-top: 25px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}

.project-hr {
    height: 2px;
    border-radius: 1px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.project-title {
    color: white;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 28px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.project-description {
    color: white;
    font-family: 'roboto';
    font-size: 14px;
    font-weight: 300;
}
.skill-badge {
    height: 22px;
    background-color: #731702;
    padding-right: 8px;
    border-radius: 5px;
    margin-right: 5px;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;
    vertical-align: middle;
    color: white;
    margin-right: 8px;
}
.project-details-button {
    color: white;
    border-style: solid;
    border-color: #F27405;
    width: 50%;
    font-size: 20px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 20px;
}
.project-details-button:hover {
    background-color:#F27405;
    color: white;
    cursor: pointer;
}

.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    padding: 15px;
}
.doc-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
    display: inline;
}
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.document {
    border-color: white;
    border-width: 1px;
    width: 100%;
    border-style: solid;
    background-color: #014040;
    border-radius: 15px;
    padding: 15px 10px;
    color: white;
    vertical-align: middle;
    margin-bottom: 15px;
}
.document-title {
    vertical-align: middle;
    color: inherit;
    font-family: 'roboto';
    font-weight: 300;
    font-size: 30px;
}
.download-button {
    display: inline;
    font-size: 20px;
    float: right;
    margin-top: 8px;
    background-color:#014040;
    border-radius: 10px;
    border-style: none;
    color:#F27405;
    cursor: pointer;
    outline: none;
    transition: .4s;
}
.download-button:focus {
    outline: none;
}
.download-button-active {
    border-color:#F27405;
    border-width: 2px;
    border-style: solid;
}
.back-icon {
    cursor: pointer;
    color: white;
    font-size:xx-large;
    display: inline-block;
    padding-right: 3%;
}
@media only screen and (max-width: 800px) {
    .doc-title {
        font-size: 40px;
    }
  }

.home-card {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: left;
    align-items: left;
    padding: 15px;
}
.home-card-center {
    margin-top: 15px;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 25px;
    border-width: 0;
    border-style: none;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px;
}
.proj-title {
    font-size: 60px;
    font-family: 'roboto';
    font-weight: 300;
    color: white;
    display: inline-block;
}
.home-hr {
    height: 4px;
    border-radius: 2px;
    border: none;
    background-color: #F27405;
    margin-right: 25px;
}
.section-icon {
    color: white;
    font-family: 'roboto';
    font-weight: 200;
    font-size: 30px;
    font-style: normal;
    vertical-align: middle;
    display: inline-block;
}
.back-icon {
    cursor: pointer;
    color: white;
    font-size:xx-large;
    display: inline-block;
    padding-right: 3%;
}
.pypi-button {
    color: #F27405;
    border-style: solid;
    border-color: #F27405;
    width: 90%;
    font-size: x-large;
    background-color: rgba(2, 115, 94, 0.35);
    border-radius: 20px;
    text-decoration: none;
    height: 50%;
}
.pypi-button:hover {
    background-color:#F27405;
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.pip-install-display {
    background-color: #F5F5F5;
    border-radius: 20px;
    width: 90%;
    text-align: center;
    position:relative;
    /* white-space: nowrap; */
    overflow: hidden;
    border-width: 2px;
    border-color: #F5F5F5;
    border-style: solid;
    height: 50%;
    display: table;
}
.pip-install-copy-background {
    background-color: #014040;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 50px;
    vertical-align: middle;

}
.copy-icon {
    cursor: pointer;
    color: white;
    font-size:x-large;
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
    
    
}
.pip-text {
    font-family: 'consolas';
    font-size: x-large;
    padding-right: 50px;
}
.overview-text {
    padding: 10px;
    font-family: 'roboto';
    color: white;
    font-weight: 300;
    font-size:large;
}
.stats-card-center {
    margin-top: 15px;
    background-color: #f5f5f5;
    border-radius: 25px;
    border-width: 0;
    color: #731702;
    font-family: 'consolas';
    border-style: none;
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 15px;
}

@media only screen and (max-width: 800px) {
    .proj-title {
        font-size: 40px;
    }
  }
